import React, { useState } from "react";
import {
  ActionsCell,
  TableCell,
  TableRow,
} from "../../../../components/Tables/styles";
import { MoreButton } from "../../../../components/Buttons/styles";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { ReactComponent as MoreIcon } from "../../../../layout/icons/more-icon.svg";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import ConfirmModal from "components/ConfirmModal";
import { Chip } from "components/Chip/Chip";
import LevelForm from "../LevelForm";
import { LevelItemProps } from "interfaces/Level";
import { deleteLevel } from "requests/levels";

interface SectorProps {
  level: LevelItemProps;
  setLevelList: React.Dispatch<React.SetStateAction<LevelItemProps[]>>;
  setSearchResults: React.Dispatch<React.SetStateAction<LevelItemProps[]>>;
}

const LevelItem: React.FC<SectorProps> = ({
  level,
  setLevelList,
  setSearchResults,
}) => {
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const handleDelete = () => {
    setDeleteConfirmModal(true);
  };

  const handleDeleteSector = () => {
    deleteLevel(level.id).then(() => {
      setDeleteConfirmModal(false);
      setLevelList((old) => old.filter((item) => item.id !== level.id));
      setSearchResults((old) => old.filter((item) => item.id !== level.id));
    });
  };

  const handleEditModal = () => {
    setEditModal(true);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          {level.name} {!!level.isNew ? <Chip>NOVO</Chip> : null}
        </TableCell>
        <TableCell>{level.bellow?.name}</TableCell>
        <TableCell>{level.above?.name}</TableCell>
        <TableCell>{level.pair?.name}</TableCell>
        <ActionsCell>
          <Menu
            direction="left"
            menuButton={
              <MoreButton>
                <MoreIcon />
              </MoreButton>
            }
            transition
          >
            <MenuItem
              onClick={handleEditModal}
            >
              Editar
            </MenuItem>
            <MenuItem
              onClick={handleDelete}
            >
              Excluir
            </MenuItem>
          </Menu>
        </ActionsCell>
      </TableRow>

      {editModal ? (
        <LevelForm
          type="edit"
          open={editModal}
          setOpen={setEditModal}
          setLevelList={setLevelList}
          dataEdit={level}
        />
      ) : null}

      {deleteConfirmModal ? (
        <ConfirmModal
          open={deleteConfirmModal}
          title="Excluir nível"
          description={`Você tem certeza que deseja excluir o seguinte nível: ${level.name}?`}
          setOpen={setDeleteConfirmModal}
          action={handleDeleteSector}
        />
      ) : null}
    </>
  );
};

export default LevelItem;
