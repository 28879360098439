import {
  ContractEffectiveType,
  ContractStatus,
  ContractType,
} from "interfaces/Contracts";

export const formationAcademicLevels = [
  { value: "1", label: "Educação infantil" },
  { value: "2", label: "Fundamental" },
  { value: "3", label: "Médio" },
  { value: "4", label: "Superior (graduação)" },
  { value: "5", label: "Pós-graduação" },
  { value: "6", label: "Mestrado" },
  { value: "7", label: "Doutorado" },
  { value: "8", label: "Tecnólogo" },
  { value: "9", label: "MBA" },
  { value: "10", label: "Especialização" },
];

export const KnowledgeAreas = [
  { value: "1", label: "Ciências Exatas" },
  { value: "2", label: "Ciências Biológicas" },
  { value: "3", label: "Engenharias" },
  { value: "4", label: "Ciências da Saúde" },
  { value: "5", label: "Ciências Humanas" },
  { value: "6", label: "Ciências Agrárias" },
  { value: "7", label: "Ciências Sociais" },
  { value: "8", label: "Linguística, Letras e Artes" },
];

export const SkinColors = [
  { value: "0", label: "Não informado" },
  { value: "1", label: "Branca" },
  { value: "2", label: "Preta" },
  { value: "3", label: "Parda" },
  { value: "4", label: "Amarela" },
  { value: "5", label: "Indígena" },
];

export const SexualOrientation = [
  { value: "0", label: "Não informado" },
  { value: "1", label: "Heterossexual" },
  { value: "2", label: "Homossexual" },
  { value: "3", label: "Bissexual" },
  { value: "4", label: "Assexual" },
  { value: "5", label: "Pansexual" },
  { value: "6", label: "Outros" },
];

export const DesvinculateMotives = [
  { value: "DISMISSAL_REQUEST", label: "Pedido de demissão" },
  { value: "SUMMARY_DISMISSAL", label: "Dispensa sem justa causa" },
  { value: "DISMISSAL_BY_JUST_COUSE", label: "Dispensa com justa causa" },
  { value: "CONTRACT_END", label: "Término do contrato de experiência" },
  {
    value: "REPEAL_BY_COMPANY",
    label: "Rescisão antecipada do contrato por iniciativa do empregador",
  },
  {
    value: "REPEAL_BY_COLLABORATOR",
    label: "Rescisão antecipada do contrato por iniciativa do colaborador",
  },
  { value: "DEATH", label: "Falecimento do colaborador" },
  { value: "OTHERS", label: "Outros" },
];

export const ContractsTypeList: { value: ContractType; label: string }[] = [
  { value: "FIXED_TIME", label: "Tempo determinado" },
  { value: "INDEFINITE_TIME", label: "Tempo indeterminado" },
  { value: "OCCASIONAL_WORK", label: "Trabalho eventual" },
  { value: "INTERNSHIP", label: "Estágio" },
  { value: "EXPERIENCE", label: "Experiência" },
  { value: "TELEWORK", label: "Teletrabalho" },
  { value: "INTERMITTENT", label: "Intermitente" },
  { value: "SELF_EMPLOYMENT", label: "Trabalho autônomo (PJ)" },
];

export const ContractsEffectiveTypeList: {
  value: ContractEffectiveType;
  label: string;
}[] = [
  { value: "INDEFINITE_TIME", label: "Tempo indeterminado" },
  { value: "TELEWORK", label: "Teletrabalho" },
  { value: "INTERMITTENT", label: "Intermitente" },
  { value: "SELF_EMPLOYMENT", label: "Trabalho autônomo (PJ)" },
];

export const BehavioralProfileCompetenceTypes = [
  { value: "1", label: "Técnica" },
  { value: "2", label: "Comportamental" },
  { value: "3", label: "Resultado" },
];

export const ContractStatusColor: { [key in ContractStatus]: string } = {
  OPEN: "#FFA928",
  RENEWED: "#0085B0",
  EXPIRED: "#990000",
  EXPIRE_IN_7: "#FF2D2D",
  EXPIRE_IN_15: "#FF5B5B",
  EXPIRE_IN_30: "#DE0000",
  EFFECTIVE: "#0EAD4D",
  RENEW_ON_END: "#0DCAF0",
  OFF_ON_END: "#CE0E2D",
  EFFECTIVE_ON_END: "#3DAE2B",
  OFF: "#BBBBBB",
};

export const ContractStatusLabelText: { [key in ContractStatus]: string } = {
  OPEN: "Aberto",
  RENEWED: "Renovado",
  EXPIRED: "Vencido",
  EXPIRE_IN_7: "Vence em 7 dias",
  EXPIRE_IN_15: "Vence em 15 dias",
  EXPIRE_IN_30: "Vence em 30 dias",
  EFFECTIVE: "Efetivo",
  RENEW_ON_END: "Renovação no vencimento",
  OFF_ON_END: "Desligamento no vencimento",
  EFFECTIVE_ON_END: "Efetivação no vencimento",
  OFF: "Encerrado",
};
