import React, { useState } from "react";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import { Button, OutlinedButton } from "components/Buttons/styles";
import ModalLoadingOverlay from "components/Modal/ModalLoadingOverlay";
import CompaniesIcon from "components/Icons/CompaniesIcon";
import { InputBase } from "components/Forms/Input/Input";
import { addToast } from "app/components/Toast";
import { CompanyData, CompanyUpdateInterface } from "interfaces/Companies";
import { updateCompany } from "requests/master";


interface UpdateCollaboratorsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  company: CompanyData;
  // description: string;
  button?: string;
  setCompany: React.Dispatch<React.SetStateAction<CompanyData>>;
  // action: () => void;
}

const UpdateCollaboratorsModal: React.FC<UpdateCollaboratorsModalProps> = ({
  open,
  setOpen,
  company,
  title,
  button,
  setCompany,
}) => {
  const [loading, setLoading] = useState(false);
  const [numberCollaborators, setNumberCollaborators] = useState<number>(
    company.number_collaborators
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = () => {
    if (!numberCollaborators) {
      addToast("Adicione um número válido", { appearance: "warning" });
      return;
    }

    setLoading(true);

    const data: CompanyUpdateInterface = {
      number_collaborators: numberCollaborators,
    };

    updateCompany(company.id, data)
      .then(() => {
        setCompany((old) => ({
          ...old,
          number_collaborators: numberCollaborators,
        }));
        setOpen(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        addToast("Erro ao atualizar a quantidade de colaboradores", {
          appearance: "error",
        });
      });
  };

  const handleChange = (e) => {
    setNumberCollaborators(e.target.value);
  };

  return (
    <Modal
      icon={<CompaniesIcon />}
      open={open}
      onClose={handleClose}
      title={title}
    >
      {loading ? <ModalLoadingOverlay /> : null}
      <ModalContent>
        <p
          style={{
            marginBottom: "10px",
          }}
        >
          Quantidade de colaboradores contratados
        </p>
        <InputBase
          onChange={handleChange}
          type="text"
          value={numberCollaborators}
          placeholder={"Informe a nova quantidade de colaboradores contratados"}
          required
        />
      </ModalContent>
      <ModalActions>
        <OutlinedButton size="small" onClick={handleClose}>
          Cancelar
        </OutlinedButton>
        <Button size="small" onClick={handleAction}>
          {button ? button : "Atualizar"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default UpdateCollaboratorsModal;
