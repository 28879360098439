import React, { useEffect, useState } from "react";
import JobFormPage from "..";
import { useParams } from "react-router-dom";
import { showJob } from "requests/jobs";
import { ShowJobItemProps } from "interfaces/Job";
import { AxiosError } from "axios";
import { addToast } from "app/components/Toast";

const FormJobEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [jobData, setJobData] = useState<ShowJobItemProps>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      showJob(id)
        .then((response) => {
          setJobData(response);
          setLoading(false);
        })
        .catch((err: AxiosError) => {
          console.error(err);
          setLoading(false);
          addToast(`Ocorreu um erro: ${err.code} / ${err.message}`, {
            appearance: "error",
          });
        });
    }
  }, []);

  if (loading) return <></>;

  return <JobFormPage dataEdit={jobData} />;
};

export default FormJobEdit;
