import styled from "styled-components";

interface TextAreaBaseProps {
  hasError: boolean;
}

export const TextareaBase = styled.textarea<TextAreaBaseProps>`
  border: ${({ hasError, theme }) =>
      hasError ? theme.colors.danger : "#afafaf"}
    1px solid;
  border-radius: 4px;
  background: #ffffff;
  width: 100%;
  padding: 8px;
  outline: none;
  resize: none;
  opacity: 1;
  font-size: 14px;
  &:focus,
  &:focus-visible {
    border: 2px solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.danger : theme.colors.primary} !important;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
`;
