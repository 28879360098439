import React, { createContext, useContext, useEffect } from "react";
import { links } from "app/config/links";
import { useAuth } from "app/hooks/useAuth";

export interface PermissionObject {
  id: string;
  slug: string;
  name: string;
  description: string | null;
  systemRole: Array<{
    name: string;
    slug: string;
  }>;
}

export interface PermissionsContextProps {}

export const PermissionsContext = createContext({});

const PermissionsProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { user } = useAuth();

  useEffect(() => {
    if (!user.config.master) {
      window.location.href = links.web.social;
    }
  }, []);

  return (
    <PermissionsContext.Provider value={{}}>
      {children}
    </PermissionsContext.Provider>
  );
};

const usePermissions = () => useContext(PermissionsContext);

export { PermissionsProvider, usePermissions };
