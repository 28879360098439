import React from "react";
import LeftArrow from "./icons/left-arrow.svg";
import RightArrow from "./icons/right-arrow.svg";

import {
  PaginationButton,
  PaginationButtons,
  PaginationSelect,
  PaginationText,
  PaginationWrapper,
} from "./styles";

const TablePagination = ({
  rowsPerPage,
  totalRows,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage + 1;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, totalRows);

  const handlePageChange = (newPage) => {
    if (newPage !== page && newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    onRowsPerPageChange(newRowsPerPage);
  };

  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  return (
    <PaginationWrapper>
      <PaginationSelect value={rowsPerPage} onChange={handleRowsPerPageChange}>
        <option value={10}>10</option>
      </PaginationSelect>
      <PaginationText>{`${startIndex}-${endIndex} de ${totalRows}`}</PaginationText>

      <PaginationButtons>
        <PaginationButton
          disabled={isFirstPage}
          onClick={() => handlePageChange(page - 1)}
        >
          <img src={LeftArrow} style={{ width: "10px" }} />
        </PaginationButton>

        <PaginationButton
          disabled={isLastPage}
          onClick={() => handlePageChange(page + 1)}
        >
          <img src={RightArrow} style={{ width: "10px" }} />
        </PaginationButton>
      </PaginationButtons>
    </PaginationWrapper>
  );
};

export default TablePagination;
