import styled from "styled-components";

interface InputBaseProps {
  hasError?: boolean;
}

export const InputBase = styled.input<InputBaseProps>`
  height: 38px;
  border: ${({ hasError, theme }) =>
      hasError ? theme.colors.danger : "#afafaf"}
    1px solid;
  border-radius: 4px;
  font-size: 14px;
  background: #ffffff;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  outline: none;
  &:focus,
  &:focus-visible {
    border: 2px solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.danger : theme.colors.primary} !important;
  }

  &::-webkit-file-upload-button {
    height: 28px;
    border: ${({ hasError, theme }) =>
        hasError ? theme.colors.danger : "#afafaf"}
      1px solid;
    border-radius: 4px;
    font-size: 14px;
    background: #ffffff;
    padding-left: 8px;
    padding-right: 8px;
    outline: none;
    cursor: pointer;
    margin-top: 4px;
  }
`;

export const SearchInputStyle = styled.div`
  position: relative;
  display: inline-block;
  input {
    width: "100% !important";
  }
`;

export const SearchButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 38px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
