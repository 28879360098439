import { links } from "app/config/links";
import Avatar from "components/Avatar";
import { OutlinedButton } from "components/Buttons/styles";
import { ChipContainer, ChipLabel } from "components/ChipDeletable";
import RenderImage from "components/RenderImage";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "components/Tables/styles";
import { WhiteContainer } from "components/WhiteContainer";
import { SingleResult } from "pages/Dashboard/SearchUser/styles";
import WhiteLabelModal from "pages/Dashboard/SearchUser/WhiteLabelModal";
import React, { useEffect, useState } from "react";
import { getCompanyCollaborators } from "requests/master";

interface GeneralDataInterface {
  resultData: any;
}

const formateDate = (date: string) => {
  if (!date) return date;
  const dateParts = date.split("-");
  const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  return formattedDate;
};

const GeneralData: React.FC<GeneralDataInterface> = ({ resultData }) => {
  const [whiteLabelModal, setWhiteLabelModal] = useState(false);
  const [collaboratorsCount, setCollaboratorsCount] = useState(null);
  const handleWhiteLabelModal = () => setWhiteLabelModal(true);

  useEffect(() => {
    if (resultData?.user.type === "COMPANY") {
      getCompanyCollaborators(resultData.profile.id).then((response) => {
        setCollaboratorsCount(response.data.meta.total);
      });
    }
  }, [resultData]);

  return (
    <WhiteContainer>
      <SingleResult style={{ overflowX: "auto" }}>
        <h2 style={{ marginBottom: "15px" }}>Dados Gerais:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Nome social:</strong>
              </TableCell>
              <TableCell>{resultData.profile.name}</TableCell>
            </TableRow>
            {resultData.user.type === "COMPANY" ? (
              <TableRow>
                <TableCell>
                  <strong>Nome do representante:</strong>
                </TableCell>
                <TableCell>
                  {resultData.profile.legal_representative ?? ""}
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell>
                <strong>Link do perfil:</strong>
              </TableCell>
              <TableCell>
                {resultData?.user?.username && (
                  <a
                    href={links.web.social + `/p/${resultData.user.username}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {links.web.social + `/p/${resultData.user.username}`}
                  </a>
                )}
              </TableCell>
            </TableRow>

            {resultData.user.type === "COMPANY" ? (
              <TableRow>
                <TableCell>
                  <strong>Atividade comercial:</strong>
                </TableCell>
                <TableCell>
                  {resultData.profile.commercial_activity ?? ""}
                </TableCell>
              </TableRow>
            ) : null}

            {resultData.user.type === "COMPANY" ? (
              <TableRow>
                <TableCell>
                  <strong>Nível:</strong>
                </TableCell>
                <TableCell>{resultData.profile.level_type ?? ""}</TableCell>
              </TableRow>
            ) : null}

            {resultData.user.type === "COMPANY" ? (
              <TableRow>
                <TableCell>
                  <strong>WhiteLabel</strong>
                </TableCell>
                <TableCell>
                  <Table>
                    <TableRow>
                      <TableCell>{resultData?.profile.partner_name}</TableCell>
                      <TableCell>
                        {resultData.profile.logo && (
                          <RenderImage
                            src={resultData.profile.logo}
                            width="120"
                            height="auto"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <OutlinedButton
                          size="small"
                          onClick={handleWhiteLabelModal}
                        >
                          Alterar
                        </OutlinedButton>
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableCell>
              </TableRow>
            ) : null}

            {resultData.user.type === "PERSON" ? (
              <TableRow>
                <TableCell>
                  <strong>Nascimento:</strong>
                </TableCell>
                <TableCell>
                  {formateDate(resultData.profile.born_date)}
                </TableCell>
              </TableRow>
            ) : null}

            {resultData.user.type === "COMPANY" ? (
              <TableRow>
                <TableCell>
                  <strong>Quantidade de colaboradores:</strong>
                </TableCell>
                <TableCell>
                  {collaboratorsCount ? collaboratorsCount : "carregando..."}
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell>
                <strong>Conta verificada:</strong>
              </TableCell>
              <TableCell>
                {!!resultData.user.is_verified ? "Sim" : "Não"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>E-mail:</strong>
              </TableCell>
              <TableCell>{resultData.user.email}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Telefones:</strong>
              </TableCell>
              <TableCell>
                {resultData.profile.phones.map((phone, index) => (
                  <ChipContainer key={index.toString()}>
                    <ChipLabel>{phone.phone_number}</ChipLabel>
                  </ChipContainer>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Data de criação da conta:</strong>
              </TableCell>
              <TableCell>
                {formateDate(resultData.user.created_at.split("T")[0])}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>PROFILE_ID:</strong>
              </TableCell>
              <TableCell>{resultData.profile.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>USER_ID:</strong>
              </TableCell>
              <TableCell>{resultData.user.id}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {whiteLabelModal && resultData ? (
          <WhiteLabelModal
            companyId={resultData.profile.id}
            open={whiteLabelModal}
            setOpen={setWhiteLabelModal}
          />
        ) : null}
      </SingleResult>
    </WhiteContainer>
  );
};

export default GeneralData;
