import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
`;

export const TableHead = styled.thead`
  background-color: #ffffff;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

export const TableBody = styled.tbody`
  & tr:nth-child(even) {
    background-color: #ffffff;
  }

  & tr:nth-child(odd) {
    background-color: #f2f2f2;
  }

  & tr:first-child {
    background-color: #f2f2f2;
  }

  & td {
    padding: 8px 16px;
  }
`;

export const TableCell = styled.td`
  padding: 0 16px;

  div.collaborator-cell {
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }
`;
interface ActionTrashCellProps {
  exceeded: boolean;
}
export const ActionTrashCell = styled.span<ActionTrashCellProps>`
display: inline-flex; /* Alterado para inline-flex */
gap: 2px;
width: auto;
align-items: center;
  color: ${({ exceeded }) => (exceeded ? '#FF0000' : '#008AC1')};
  border-color: ${({ exceeded }) => (exceeded ? '#FF0000' : '#008AC1')};
  border-bottom: 1px dashed; /* Adicione esta linha para a borda inferior */

  cursor: pointer;
`;


export const ActionsCell = styled.td`
  text-align: right;
`;

export const TableHeaderCell = styled.th`
padding: 8px 16px;
text-align: left;
  font-weight: 600;
`;

export const TableRow = styled.tr`
  &:hover {
    background-color: #ebebeb;
  }
`;
