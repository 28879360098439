import CircularProgress from "components/CircularProgress";
import styled from "styled-components";

const LoadingComponentStyle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background: rgba(255, 255, 255, 0.8);
`;

export default () => {
  return (
    <LoadingComponentStyle>
      <CircularProgress size={60} thickness={6} />
    </LoadingComponentStyle>
  );
};
