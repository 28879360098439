import React, { useEffect, useState } from "react";
import { Button, OutlinedButton } from "components/Buttons/styles";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import Radio, { RadioGroup } from "components/Forms/Radio";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CourseItemProps } from "..";
import { getCertifications } from "requests/certifications";
import { CertificationItemProps } from "interfaces/Certification";
import Select from "components/Forms/Select";
import CertificationIcon from "components/Icons/CertificationIcon";

interface CourseModalFormProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCourseList: React.Dispatch<React.SetStateAction<CourseItemProps[]>>;
}

const schema = yup.object({
  id: yup
    .string()
    .required("Campo obrigatório"),
});

interface CourseForm {
  name?: string;
  id: string;
}

const CourseModalForm: React.FC<CourseModalFormProps> = ({
  open,
  setOpen,
  setCourseList,
}) => {
  const [typeEvaluation, setTypeEvaluation] = useState<
    "DIFFERENTIAL" | "REQUIRED"
  >("DIFFERENTIAL");
  const [certificationsList, setCertificationsList] = useState<CertificationItemProps[]>([]);

  const methods = useForm<CourseForm>({
    resolver: yupResolver(schema),
  });

  const handleClose = () => setOpen(false);
  const handleChange = (e) => setTypeEvaluation(e.target.value);

  useEffect(() => {
    getCertifications(1, 9999).then((response) => {
      setCertificationsList(response.data);
    });

  }, []);

  const onSubmit = (data: CourseForm) => {
    const selectedCertification = certificationsList.find(certification => certification.id === data.id);

    if (selectedCertification) {
      const newData = {
        ...data,
        name: selectedCertification.name,
        type_evaluation: typeEvaluation,
      };

      setCourseList((old) => [...old, newData]);
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      icon={<CertificationIcon />}
      title="Adicionar Certificação"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalContent>
            <GridContainer>
              <GridRow>
                <GridCol xs={12} md={12} lg={12}>
                  <Select
                    label="Nome do curso"
                    required={true}
                    inputProps={{
                      ...methods.register("id"),
                    }}

                  >
                    {certificationsList.map((item, i) => (
                      <option value={item.id}>{item.name}</option>

                    ))}
                  </Select>
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol xs={12} sm={12} lg={12}>
                  <RadioGroup direction="row">
                    <Radio
                      label="Diferencial"
                      value="DIFFERENTIAL"
                      checked={typeEvaluation === "DIFFERENTIAL"}
                      onChange={handleChange}
                    />
                    <Radio
                      label="Obrigatório"
                      value="REQUIRED"
                      checked={typeEvaluation === "REQUIRED"}
                      onChange={handleChange}
                    />
                  </RadioGroup>
                </GridCol>
              </GridRow>
            </GridContainer>
          </ModalContent>
          <ModalActions>
            <OutlinedButton size="small" onClick={handleClose}>
              Cancelar
            </OutlinedButton>
            <Button size="small" type="submit">
              Adicionar
            </Button>
          </ModalActions>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CourseModalForm;
