import styled from "styled-components";

type AvatarWrapperProps = {
  size?: string;
};

export const AvatarWrapper = styled.div<AvatarWrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ size }) => size || "12px"};
  width: ${({ size }) => size || "30px"};
  height: ${({ size }) => size || "30px"};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

interface AvatarInitialsProps {
  fontSize?: string;
}

export const AvatarInitials = styled.span<AvatarInitialsProps>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
`;
