import Layout from "layout/Layout";
import {
  GridCol,
  GridContainer,
  GridRow,
  HeaderPage,
} from "layout/components/page-styles";
import { RecordsPage } from "pages/Records/styles";
import React, { useEffect, useState } from "react";
import { WhiteContainer } from "../styles";
import Input from "components/Forms/Input";
import Textarea from "components/Forms/Textarea";
import { FieldWrapped } from "components/Forms/FieldWrapped";
import { Label } from "components/Forms/Label";
import SelectAutocomplete, {
  AutoCompleteOptions,
} from "components/Forms/SelectAutocomplete";
import { SectorItemProps } from "interfaces/Sector";
import { LevelItemProps } from "interfaces/Level";
import { getSectors } from "requests/sectors";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { getLevels } from "requests/levels";
import { InputBase } from "components/Forms/Input/Input";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Tables/styles";
import FormationModal from "./FormationModal";
import { Button, OutlinedButton } from "components/Buttons/styles";
import CourseModalForm from "./CourseModal";
import * as yup from "yup";
import {
  CompetenceInterface,
  CompetencesItemProps,
  CreateJobAcademicFormation,
  CreateJobCourse,
  CreateJobProps,
  JobItemProps,
  ShowJobItemProps,
} from "interfaces/Job";
import { yupResolver } from "@hookform/resolvers/yup";
import { HelperText } from "components/Forms/Helpers/HelperText";
import { getCompetencesList, saveJob, updateJob } from "requests/jobs";
import { useJobsContext } from "../context";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { convertStringToInt } from "utils/utils";
import { KnowledgeAreas, formationAcademicLevels } from "utils/lists";
import LoadingComponent from "components/LoadingComponent";
import { SelectBase } from "components/Forms/Select/Select";
import BehavioralProfiles from "./BehavioralProfiles";
import BehavioralProfileModal from "./BehavioralProfiles/BehavioralProfileModal";
import { CurrencyInput } from "react-currency-mask";
import { addToast } from "app/components/Toast";

const maxLength = {
  name: 100,
  short_description: 2000,
  detailed_description: 5000,
};

const convertForAutocomplete = (data: SectorItemProps[] | LevelItemProps[]) =>
  data.map((item) => ({
    value: item.id,
    label: item.name,
  }));

const getKnowAreaLabel = (id: string) => {
  const area = KnowledgeAreas.find((area) => area.value === id);
  return area ? area.label : "Não informado";
};

const getLevelLabel = (id: string) => {
  const level = formationAcademicLevels.find((level) => level.value === id);
  return level ? level.label : "Não informado";
};

export interface FormationItemProps {
  level: { value: string; label: string } | null;
  knowledge_area: { value: string; label: string } | null;
  study_area: { value: number; label: string } | null;
  type_evaluation: "DIFFERENTIAL" | "REQUIRED";
}

export interface CourseItemProps {
  id: string;
  name?: string;
  type_evaluation: "DIFFERENTIAL" | "REQUIRED";
}

const getTypeLabel = (value: "DIFFERENTIAL" | "REQUIRED") => {
  const option = {
    DIFFERENTIAL: "Diferencial",
    REQUIRED: "Obrigatório",
  };

  return option[value];
};

const schema = yup.object({
  name: yup
    .string()
    .required("Campo obrigatório")
    .max(maxLength.name, `Quantidade máxima de caracteres: ${maxLength.name}`),
  level_id: yup.string(),
  sector_id: yup.string(),
  short_description: yup
    .string()
    .required("Campo obrigatório")
    .max(
      maxLength.short_description,
      `Quantidade máxima de caracteres: ${maxLength.short_description}`
    ),
  detailed_description: yup
    .string()
    .max(
      maxLength.detailed_description,
      `Quantidade máxima de caracteres: ${maxLength.detailed_description}`
    )
    .nullable(),
  initial_salary: yup.string().required("Campo obrigatório"),
  final_salary: yup
    .string()
    .required("Campo obrigatório")
    .typeError("Insira um número válido para o salário final"),
});

interface JobFormPageProps {
  dataEdit?: ShowJobItemProps;
}

const JobFormPage: React.FC<JobFormPageProps> = ({ dataEdit }) => {
  const [loading, setLoading] = useState(false);
  const [sectorOptions, setSectorOptions] = useState<AutoCompleteOptions[]>([]);
  const [levelOptions, setLevelOptions] = useState<AutoCompleteOptions[]>([]);

  const [formationList, setFormationList] = useState<FormationItemProps[]>([]);
  const [courseList, setCourseList] = useState<CourseItemProps[]>(
   dataEdit?.specialization_courses?.length
      ? dataEdit?.specialization_courses
      : []
  );
  const [behavioralProfileList, setBehavioralProfileList] = useState<
    CompetenceInterface[]
  >(dataEdit?.behavioral_profiles ?? []);

  const [formationModalOpen, setFormationModalOpen] = useState(false);
  const [courseModalOpen, setCourseModalOpen] = useState(false);
  const [behavioralModalOpen, setBehavioralModalOpen] = useState(false);

  const [allCompetencesList, setAllCompetencesList] = useState<
    CompetencesItemProps[]
  >([]);

  const { handleAddJob } = useJobsContext();
  const navigate = useNavigate();

  const methods = useForm<CreateJobProps>({
    //resolver: yupResolver(schema),
  });

  const getSector = (sectorId: string) => {
    const sector = sectorOptions.find((item) => item.value === sectorId);
    return sector?.label;
  };

  const getLevel = (levelId: string) => {
    const level = levelOptions.find((item) => item.value === levelId);
    return level?.label;
  };

  useEffect(() => {
    //getCompetencesList().then((response) =>
     //setAllCompetencesList(response)
    //);
  }, []);


  useEffect(() => {
    if (
      dataEdit &&
      dataEdit.academic_formations &&
      dataEdit.academic_formations.length > 0
    ) {
      const updatedList: FormationItemProps[] =
        dataEdit.academic_formations.map((item) => ({
          level:
            { value: item.level, label: getLevelLabel(item.level) } ?? null,
          type_evaluation: item.type_evaluation.toUpperCase() as "DIFFERENTIAL" | "REQUIRED",
          knowledge_area: {
            value: item.knowledge_area,
            label: getKnowAreaLabel(item.knowledge_area),
          },
          study_area: item?.study_area 
            ? { value: item.study_area.id, label: item.study_area.name }
            : null,
        }));
  
      setFormationList(updatedList);
    }
  }, []);



  useEffect(() => {
    getSectors(1, 99999).then((response) => {
      const formatedSectors = convertForAutocomplete(response.data);
      setSectorOptions(formatedSectors);
    });

    getLevels(1, 99999).then((response) => {
      const formatedLevels = convertForAutocomplete(response.data);
      setLevelOptions(formatedLevels);
    });
  }, []);

  const onSubmit = (data: CreateJobProps) => {

    let initialSalary =
      data.initial_salary !== null
        ? Math.round(data.initial_salary * 100)
        : data.initial_salary;
    let finalSalary =
      data.final_salary !== null
        ? Math.round(data.final_salary * 100)
        : data.final_salary;

    if (
      convertStringToInt(String(initialSalary)) >=
      convertStringToInt(String(finalSalary))
    ) {
      methods.setError("initial_salary", {
        message: "O salário inicial deve ser inferior ao salário final",
      });

      methods.setError("final_salary", {
        message: "O salário final deve ser superior ao salário inicial",
      });

      return;
    }
    const formations: CreateJobAcademicFormation[] = formationList.map(
      (item) => {
        return {
          level: item.level?.value ?? "",
          knowledge_area: item.knowledge_area?.value ?? null,
          type_evaluation: item.type_evaluation,
          study_area_id: item.study_area?.value ?? null,
        };
      }
    );

    const courses: CreateJobCourse[] = courseList.map((item) => {
      return {
        id: item.id,
        type_evaluation: item.type_evaluation,
      };
    });

    const newData: CreateJobProps = {
      ...data,
      initial_salary: convertStringToInt(String(initialSalary)),
      final_salary: convertStringToInt(String(finalSalary)),
      academic_formations: formations.length > 0 ? formations : null,
      specialization_courses: courses.length > 0 ? courses : null,
      behavioral_profiles:
        behavioralProfileList.length > 0 ? behavioralProfileList : null,
      profile_disc: data.profile_disc ? data.profile_disc : null,
      sector_id: data.sector_id ?? null,
      level_id: data.level_id ?? null,
    };

    if (dataEdit) {
      const newBehivoralProfile = newData.behavioral_profiles?.map((item) => ({
        ...item,
        type: String(item.type),
      }));

      const newEditedData = {
        ...newData,
        behavioral_profiles:
          newBehivoralProfile !== undefined && newBehivoralProfile.length > 0
            ? newBehivoralProfile
            : null,
      };

      setLoading(true);

      updateJob(dataEdit.id, newEditedData)
        .then(() => {
          addToast(`O cargo ${dataEdit.name} foi editado com sucesso.`, {
            appearance: "success",
          });
          setTimeout(() => {
            setLoading(false);
            navigate(`/records/jobs`);
          }, 1000);
        })
        .catch((err: AxiosError) => {
          console.error(err);
          addToast(`Ocorreu um erro. ${err.code} / ${err.message}`, {
            appearance: "error",
          });
          setLoading(false);
        });

      return;
    }

    setLoading(true);
    saveJob(newData)
      .then((response: JobItemProps) => {
        handleAddJob(response);
        addToast(`O cargo ${newData.name} foi criado com sucesso.`, {
          appearance: "success",
        });
        setTimeout(() => {
          setLoading(false);
          navigate(`/records/jobs`);
        }, 1000);
      })
      .catch((err: AxiosError) => {
        addToast(`Ocorreu um erro. ${err.code} / ${err.message}`, {
          appearance: "error",
        });
        setLoading(false);
      });


  };

  return (
    <>
      <Layout>
        <RecordsPage>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <GridContainer>
                <GridRow>
                  <GridCol xs={12} md={12} lg={12}>
                    <HeaderPage>
                      <h1>{dataEdit ? "Editar cargo" : "Cadastrar cargo"}</h1>
                      <Button disabled={loading} type="submit">
                        Salvar cargo
                      </Button>
                    </HeaderPage>
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol xs={12} md={12} lg={12}>
                    <WhiteContainer style={{ position: "relative" }}>
                      {/*
                      {loading ? <LoadingComponent /> : null}
  */}
                      <GridContainer>
                        <GridRow>
                          <GridCol xs={12} md={12} lg={12}>
                            <Input
                              required={true}
                              label="Nome do cargo"
                              limit={maxLength.name}
                              inputProps={{
                                ...methods.register("name"),
                                defaultValue: dataEdit?.name,
                              }}
                              error={methods.formState.errors.name}
                            />
                          </GridCol>
                        </GridRow>
                        <GridRow>
                          <GridCol xs={12} md={6} lg={6}>
                            <FieldWrapped>
                              <Label>Setor do cargo</Label>
                              <SelectAutocomplete
                                defaultValue={
                                  dataEdit?.sector_id
                                    ? {
                                      value: dataEdit.sector_id,
                                      label:
                                        getSector(dataEdit.sector_id) ?? "",
                                    }
                                    : null
                                }
                                name="sector_id"
                                options={sectorOptions}
                              />
                            </FieldWrapped>
                          </GridCol>
                          <GridCol xs={12} md={6} lg={6}>
                            <FieldWrapped>
                              <Label>Nível do cargo</Label>
                              <SelectAutocomplete
                                defaultValue={
                                  dataEdit?.level_id
                                    ? {
                                      value: dataEdit.level_id,
                                      label:
                                        getLevel(dataEdit.level_id) ?? "",
                                    }
                                    : null
                                }
                                name="level_id"
                                options={levelOptions}
                              />
                            </FieldWrapped>
                          </GridCol>
                        </GridRow>
                        <GridRow>
                          <GridCol xs={12} md={12} lg={12}>
                            <Textarea
                              required={true}
                              label="Descrição curta do cargo"
                              limit={maxLength.short_description}
                              inputProps={{
                                ...methods.register("short_description"),
                                defaultValue: dataEdit?.short_description,
                              }}
                              error={methods.formState.errors.short_description}
                            />
                          </GridCol>
                        </GridRow>
                        <GridRow>
                          <GridCol xs={12} md={12} lg={12}>
                            <Textarea
                              rows={5}
                              required={false}
                              label="Descrição detalhada do cargo e suas atribuições"
                              limit={maxLength.detailed_description}
                              inputProps={{
                                ...methods.register("detailed_description"),
                                defaultValue:
                                  dataEdit?.detailed_description ?? undefined,
                              }}
                              error={
                                methods.formState.errors.detailed_description
                              }
                            />
                          </GridCol>
                        </GridRow>
                        <GridRow>
                          <GridCol xs={12} md={6} lg={6}>
                            <FieldWrapped>
                              <Label required={true}>
                                Faixa salarial inicial
                              </Label>

                              <Controller
                                control={methods.control}
                                defaultValue={
                                  dataEdit?.initial_salary
                                    ? dataEdit?.initial_salary / 100
                                    : 0
                                }
                                name="initial_salary"
                                render={({
                                  field: { onChange, value, ...rest },
                                }) => (
                                  <CurrencyInput
                                    onChangeValue={(_, value) =>
                                      onChange(value)
                                    }
                                    value={value || 0}
                                    InputElement={<InputBase />}
                                    {...rest}
                                  />
                                )}
                              />

                              {methods.formState.errors?.initial_salary && (
                                <HelperText color="danger">
                                  {
                                    methods.formState.errors?.initial_salary
                                      .message
                                  }
                                </HelperText>
                              )}
                            </FieldWrapped>
                          </GridCol>
                          <GridCol xs={12} md={6} lg={6}>
                            <FieldWrapped>
                              <Label required={true}>
                                Faixa salarial final
                              </Label>

                              <Controller
                                control={methods.control}
                                name="final_salary"
                                defaultValue={
                                  dataEdit?.final_salary
                                    ? dataEdit?.final_salary / 100
                                    : 0
                                }
                                render={({
                                  field: { onChange, value, ...rest },
                                }) => (
                                  <CurrencyInput
                                    onChangeValue={(_, value) =>
                                      onChange(value)
                                    }
                                    value={value || 0}
                                    InputElement={<InputBase />}
                                    {...rest}
                                  />
                                )}
                              />

                              {methods.formState.errors?.final_salary && (
                                <HelperText color="danger">
                                  {
                                    methods.formState.errors?.final_salary
                                      .message
                                  }
                                </HelperText>
                              )}
                            </FieldWrapped>
                          </GridCol>
                        </GridRow>
                      </GridContainer>
                    </WhiteContainer>

                    {/* formacao academica */}
                    
                    <WhiteContainer
                      style={{ marginTop: "10px", position: "relative" }}
                    >
                      {loading ? <LoadingComponent /> : null}
                      <GridContainer>
                        <GridRow>
                          <GridCol xs={12} md={12} lg={12}>
                            <strong style={{ fontWeight: 600 }}>
                              Formação acadêmica
                            </strong>
                          </GridCol>
                        </GridRow>
                        <GridRow>
                          <GridCol xs={12} md={12} lg={12}>
                            {formationList && formationList.length > 0 && (
                              <Table style={{ marginBottom: "10px" }}>
                                <TableHead>
                                  <TableCell>Nível de formação</TableCell>
                                  <TableCell>Área de conhecimento</TableCell>
                                  <TableCell>Tipo</TableCell>
                                  <TableCell>Área de estudo</TableCell>
                                  <TableCell></TableCell>
                                </TableHead>
                                <TableBody>
                                  {formationList.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{item.level?.label}</TableCell>
                                      <TableCell>
                                        {item.knowledge_area?.label}
                                      </TableCell>
                                      <TableCell>
                                        {getTypeLabel(item.type_evaluation)}
                                      </TableCell>
                                      <TableCell>
                                        {item.study_area?.label}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          display: "flex",
                                          justifyContent: "right",
                                        }}
                                      >
                                        <OutlinedButton
                                          type="button"
                                          size="small"
                                          onClick={() =>
                                            setFormationList((old) =>
                                              old.filter(
                                                (filtered, i) => i !== index
                                              )
                                            )
                                          }
                                        >
                                          Remover
                                        </OutlinedButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            )}
                            <OutlinedButton
                              type="button"
                              onClick={() => setFormationModalOpen(true)}
                            >
                              Adicionar formação
                            </OutlinedButton>
                          </GridCol>
                        </GridRow>
                      </GridContainer>
                    </WhiteContainer>
                    
                    {/* fim da formacao academica */}

                    {/* cursos de especializacao */}
                    <WhiteContainer
                      style={{ marginTop: "10px", position: "relative" }}
                    >
                      {loading ? <LoadingComponent /> : null}
                      <GridContainer>
                        <GridRow>
                          <GridCol xs={12} md={12} lg={12}>
                            <strong style={{ fontWeight: 600 }}>
                              Cursos de especialização
                            </strong>
                          </GridCol>
                        </GridRow>
                        <GridRow>
                          <GridCol xs={12} md={12} lg={12}>
                            {courseList && courseList.length > 0 && (
                              <Table style={{ marginBottom: "10px" }}>
                                <TableHead>
                                  <TableCell>Título do curso</TableCell>
                                  <TableCell>Tipo</TableCell>
                                  <TableCell></TableCell>
                                </TableHead>
                                <TableBody>
                                  {courseList.map(
                                    (item: CourseItemProps, index: number) => (
                                      <TableRow key={item.id}>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>
                                          {getTypeLabel(item.type_evaluation)}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          <OutlinedButton
                                            type="button"
                                            size="small"
                                            onClick={() =>
                                              setCourseList((old) =>
                                                old.filter(
                                                  (item, i) => i !== index
                                                )
                                              )
                                            }
                                          >
                                            Remover
                                          </OutlinedButton>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            )}
                            <OutlinedButton
                              type="button"
                              onClick={() => {
                                setCourseModalOpen(true);
                              }}
                            >
                              Adicionar curso
                            </OutlinedButton>
                          </GridCol>
                        </GridRow>
                      </GridContainer>
                    </WhiteContainer>
                    {/* fim do cursos de especializacao */}

                    
                    <BehavioralProfiles
                      setModalOpen={setBehavioralModalOpen}
                      allCompetencesList={allCompetencesList}
                      competencesList={behavioralProfileList}
                      setCompetencesList={setBehavioralProfileList}
                    />
                    

                    {/* perfil disc */}
                     
                    <WhiteContainer
                      style={{ marginTop: "10px", position: "relative" }}
                    >
                      {loading ? <LoadingComponent /> : null}
                      <GridContainer>
                        <GridRow>
                          <GridCol xs={12} md={12} lg={12}>
                            <strong style={{ fontWeight: 600 }}>
                              Perfil DISC
                            </strong>
                            <p
                              style={{
                                marginTop: "5px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              O teste DISC é uma ferramenta que auxilia na
                              compreensão dos diferentes padrões comportamentais
                              dos individuos. A sigla DISC significa:
                              Dominânica, Influência, Estabilidade e
                              Conformidade.
                            </p>
                          </GridCol>
                        </GridRow>
                        <GridRow>
                          <GridCol xs={12} md={6} lg={6}>
                            <SelectBase
                              key={dataEdit?.profile_disc}
                              defaultValue={dataEdit?.profile_disc ?? ""}
                              {...methods.register("profile_disc")}
                            >
                              <option disabled selected value="">
                                {" "}
                                -- selecione uma opção --{" "}
                              </option>
                              <option value="compliance">Conformidade</option>
                              <option value="influence">Influência</option>
                              <option value="stability">Estabilidade</option>
                              <option value="dominance">Dominância</option>
                            </SelectBase>
                          </GridCol>
                        </GridRow>
                      </GridContainer>
                    </WhiteContainer>
                    
                    {/* fim perfil disc */}
                  </GridCol>
                </GridRow>
              </GridContainer>
            </form>
          </FormProvider>

          {courseModalOpen && (
            <CourseModalForm
              open={courseModalOpen}
              setOpen={setCourseModalOpen}
              setCourseList={setCourseList}
            />
          )}
      

          {formationModalOpen && (
            <FormationModal
              open={formationModalOpen}
              setOpen={setFormationModalOpen}
              setFormationList={setFormationList}
            />
          )}
 

          {behavioralModalOpen && (
            <BehavioralProfileModal
              setSelectedCompetences={setBehavioralProfileList}
              open={behavioralModalOpen}
              setOpen={setBehavioralModalOpen}
              allCompetencesList={allCompetencesList}
            />
          )}


        </RecordsPage>
      </Layout>
    </>
  );
};

export default JobFormPage;
