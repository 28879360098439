import styled from "styled-components";

export const SingleResult = styled.div`
  h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .view-container {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-bottom: 16px;
  }

  strong {
    font-weight: 600;
  }
`;
export const Title = styled.h1`
display: flex;
align-items: center;
gap: 1rem;

`;