// ESSA PÁGINA NÃO É MAIS UTILIZADA.
// FICARÁ AQUI APENAS POR ALGUM TEMPO PARA O APROVEITAMENTO DAS FUNÇÕES QUE EXISTEM NELA


import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import Layout from "layout/Layout";
import {
  GridCol,
  GridContainer,
  GridRow,
  HeaderPage,
} from "layout/components/page-styles";
import { WhiteContainer } from "components/WhiteContainer";
import { FieldWrapped } from "components/Forms/FieldWrapped";
import { Label } from "components/Forms/Label";
import { SelectBase } from "components/Forms/Select/Select";
import { InputBase } from "components/Forms/Input/Input";
import { Button, ButtonLink, OutlinedButton } from "components/Buttons/styles";
import LoadingComponent from "components/LoadingComponent";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "components/Tables/styles";
import { SingleResult, Title } from "./styles";
import Avatar from "components/Avatar";
import { SearchResultProps } from "interfaces/Master";
import { Controller, useForm } from "react-hook-form";
import {
  SearchUserProps,
  getCompanyCollaborators,
  searchUser,
} from "requests/master";
import { ChipContainer, ChipLabel } from "components/ChipDeletable";
import RenderImage from "components/RenderImage";
import { NavMenu, NavMenuItem } from "components/NavMenu/styles";
import EmailsLog from "./EmailsLog";
import { addToast } from "app/components/Toast";
import WhiteLabelModal from "./WhiteLabelModal";
import AutentiqueModal from "./AutentiqueModal";
import api from "services/api";
import LinkRedirectModal from "./LinkRedirectModal";
import { useNavigate } from "react-router-dom";

import AsyncSelect from "react-select/async";
import { links } from "app/config/links";
import { GroupBase, OptionsOrGroups, MenuPlacement, components } from "react-select";

const formateDate = (date: string) => {
  if (!date) return date;
  const dateParts = date.split("-");
  const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  return formattedDate;
};

interface UserOptions {
  value: string;
  label: string;
}

const MINIMUM_INPUT_LENGTH = 3;

const CustomOption = (props) => {
  return (
    <components.Option data-cy="components.Option" {...props}>
      <div
        style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
      >
          <Avatar
            name={props.data.label}
            src={props.data.avatar}
          />
        {props.data.label}
      </div>
    </components.Option>
  );
};

const SingleValueSelected = (props) => {
  return (
    <components.SingleValue data-cy="components.SingleValue" {...props}>
      <div
        data-cy="SelectAutocomplete-div"
        style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
      >
        {props.data.hasOwnProperty('avatar') && (
          <Avatar
            data-cy="Avatar"
            name={props.data.label}
            src={props.data.avatar}
            size="25px"
          />
        )}
        {props.data.label}
      </div>
    </components.SingleValue>
  );
};

const SearchUser: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [resultData, setResultData] = useState<SearchResultProps>();
  const [selectedTab, setSelectedTab] = useState<"DATA" | "EMAIL_LOG">("DATA");
  const { register, handleSubmit, watch, control } = useForm<SearchUserProps>({
    defaultValues: {
      type: "search",
    },
  });
  const [whiteLabelModal, setWhiteLabelModal] = useState(false);
  const [collaboratorsCount, setCollaboratorsCount] = useState(null);
  const [autentiqueModal, setAutentiqueModal] = useState(false);
  const [linkStepModal, setLinkStepModal] = useState(false);
  const [autentiqueApiKey, setAutentiqueApiKey] = useState<string | null>(null);

  const navigate = useNavigate();

  const getAutentiqueApiKey = async (companyId: string) => {
    const response = await api.get(`/companies/${companyId}/signature/`);
    return response.data;
  };

  const onSubmit = (data: SearchUserProps) => {
    setLoading(true);

    const typesFromApiRoute = data.type === "search" ? "username" : data.type;

    const newData = {
      type: typesFromApiRoute,
      // @ts-ignore
      value: data.type === "search" ? data.value.value : data.value,
    };

    searchUser(newData)
      .then((response) => {
        setResultData(response);

        navigate("/users/view", { state: response });

        // getAutentiqueApiKey(response.profile.id).then((response) =>
        //   setAutentiqueApiKey(response.apiKey)
        // );

        // setShowResult(true);
        // setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setResultData(undefined);
        addToast(`Houve um erro: ${err.message}`, {
          appearance: "error",
        });
      });
  };
  const updateResultData = (newData: any) => {
    setResultData((prevData) => {
      if (!prevData) return prevData;
      return {
        ...prevData,
        profile: {
          ...prevData.profile,
          redirects: newData.profile.redirects,
        },
      };
    });
  };

  const handleTab = (tab: "DATA" | "EMAIL_LOG") => {
    setSelectedTab(tab);
  };

  const handleWhiteLabelModal = () => setWhiteLabelModal(true);

  useEffect(() => {
    if (resultData?.user.type === "COMPANY") {
      getCompanyCollaborators(resultData.profile.id).then((response) => {
        setCollaboratorsCount(response.data.meta.total);
      });
    }
  }, [resultData]);

  const openAutentiqueModal = () => {
    setAutentiqueModal(true);
  };
  const openStepModal = () => {
    setLinkStepModal(true);
  };

  const promiseOptions = debounce(
    (
      inputValue: string
    ): Promise<OptionsOrGroups<UserOptions, GroupBase<UserOptions>>> => {

      if (inputValue.length < MINIMUM_INPUT_LENGTH) {
        return new Promise((resolve) => resolve([]));
      }

      return new Promise((resolve, reject) => {
        api
          .get(links.api.social + `/profile/name/search?search=${inputValue}`)
          .then((response) => {
            const formattedData = response.data.map((item: any) => ({
              value: item.username,
              label: item.name,
            }));
            resolve(formattedData);
          })
          .catch((err) => {
            console.error("Erro ao buscar usuário", err);
            reject(err);
          });
      });
    },
    200
  );

  return (
    <>
      <Layout>
        <GridContainer style={{ marginBottom: "16px" }}>
          <GridRow>
            <GridCol xs={12} md={12} lg={12}>
              <HeaderPage>
                <Title>Encontrar conta</Title>
              </HeaderPage>
            </GridCol>
          </GridRow>
        </GridContainer>
        <GridContainer>
          <WhiteContainer>
            {loading ? <LoadingComponent /> : null}
            <GridContainer>
              <GridRow>
                <GridCol xs={12} md={12} lg={12}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <GridContainer style={{ position: "relative" }}>
                      {loading ? <LoadingComponent /> : null}
                      <GridRow style={{ alignItems: "end" }}>
                        <GridCol xs={12} md={3} lg={3}>
                          <FieldWrapped>
                            <Label required={true}>Pesquisar por:</Label>
                            <SelectBase {...register("type")}>
                              <option value="search">
                                Encontrar na InCicle
                              </option>
                              <option value="email">E-mail</option>
                              <option value="username">Nome de usuário</option>
                            </SelectBase>
                          </FieldWrapped>
                        </GridCol>
                        <GridCol xs={12} md={6} lg={6}>
                          <FieldWrapped>
                            <Label required={true}>Termo da pesquisa:</Label>
                            {watch("type") === "search" ? (
                              <Controller
                                control={control}
                                name="value"
                                render={({ field }) => (
                                  <AsyncSelect
                                    {...field}
                                    placeholder="Pesquise pelo nome da conta.."
                                    isClearable
                                    loadOptions={promiseOptions}
                                    noOptionsMessage={({ inputValue }) =>
                                      inputValue.length < MINIMUM_INPUT_LENGTH
                                        ? `Digite pelo menos ${MINIMUM_INPUT_LENGTH} caracteres`
                                        : "Nenhuma opção encontrada"
                                    }
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption);
                                    }}
                                    components={{
                                      Option: CustomOption,
                                      SingleValue: SingleValueSelected
                                    }}
                                  />
                                )}
                              />
                            ) : (
                              <InputBase {...register("value")} />
                            )}
                          </FieldWrapped>
                        </GridCol>
                        <GridCol xs={12} md={3} lg={3}>
                          <Button
                            size="small"
                            type="submit"
                            style={{ width: "100%" }}
                          >
                            Encontrar usuário
                          </Button>
                        </GridCol>
                      </GridRow>
                    </GridContainer>
                  </form>
                </GridCol>
                {showResult && resultData ? (
                  <GridCol
                    xs={12}
                    md={8}
                    lg={8}
                    style={{
                      borderLeft: "1px solid #CCC",
                      paddingLeft: "16px",
                    }}
                  >
                    <div style={{ marginBottom: "16px" }}>
                      <NavMenu>
                        <NavMenuItem
                          onClick={() => handleTab("DATA")}
                          className={selectedTab === "DATA" ? "active" : ""}
                        >
                          Dados
                        </NavMenuItem>
                        <NavMenuItem
                          onClick={() => handleTab("EMAIL_LOG")}
                          className={
                            selectedTab === "EMAIL_LOG" ? "active" : ""
                          }
                        >
                          Log de e-mails (beta)
                        </NavMenuItem>
                      </NavMenu>
                    </div>

                    {selectedTab === "DATA" ? (
                      <SingleResult style={{ overflowX: "auto" }}>
                        <h2>Resultado encontrado:</h2>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <strong>Nome:</strong>
                              </TableCell>
                              <TableCell>{resultData.profile.name}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Nome social:</strong>
                              </TableCell>
                              <TableCell>{resultData.profile.name}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Avatar:</strong>
                              </TableCell>
                              <TableCell>
                                <Avatar
                                  name={resultData.profile.name}
                                  src={resultData.profile.avatar ?? ""}
                                />
                              </TableCell>
                            </TableRow>

                            {resultData.user.type === "COMPANY" ? (
                              <TableRow>
                                <TableCell>
                                  <strong>Nome do representante:</strong>
                                </TableCell>
                                <TableCell>
                                  {resultData.profile.legal_representative ??
                                    ""}
                                </TableCell>
                              </TableRow>
                            ) : null}

                            {resultData.user.type === "COMPANY" ? (
                              <TableRow>
                                <TableCell>
                                  <strong>Atividade comercial:</strong>
                                </TableCell>
                                <TableCell>
                                  {resultData.profile.commercial_activity ?? ""}
                                </TableCell>
                              </TableRow>
                            ) : null}

                            {resultData.user.type === "COMPANY" ? (
                              <TableRow>
                                <TableCell>
                                  <strong>Nível:</strong>
                                </TableCell>
                                <TableCell>
                                  {resultData.profile.level_type ?? ""}
                                </TableCell>
                              </TableRow>
                            ) : null}

                            {resultData.user.type === "COMPANY" ? (
                              <TableRow>
                                <TableCell>
                                  <strong>WhiteLabel</strong>
                                </TableCell>
                                <TableCell>
                                  <Table>
                                    <TableRow>
                                      <TableCell>
                                        {resultData?.profile.partner_name}
                                      </TableCell>
                                      <TableCell>
                                        {resultData.profile.logo && (
                                          <RenderImage
                                            src={resultData.profile.logo}
                                            width="120"
                                            height="auto"
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <OutlinedButton
                                          size="small"
                                          onClick={handleWhiteLabelModal}
                                        >
                                          Alterar
                                        </OutlinedButton>
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </TableCell>
                              </TableRow>
                            ) : null}

                            {resultData.user.type === "COMPANY" ? (
                              <TableRow>
                                <TableCell>
                                  <strong>Autentique API Key</strong>
                                </TableCell>
                                <TableCell
                                  style={{
                                    display: "flex",
                                    gap: "2px",
                                    flexDirection: "column",
                                  }}
                                >
                                  {autentiqueApiKey ?? ""}
                                  <ButtonLink onClick={openAutentiqueModal}>
                                    {autentiqueApiKey ? "Editar" : "Adicionar"}
                                  </ButtonLink>
                                </TableCell>
                              </TableRow>
                            ) : null}

                            {resultData.user.type === "PERSON" ? (
                              <TableRow>
                                <TableCell>
                                  <strong>Nascimento:</strong>
                                </TableCell>
                                <TableCell>
                                  {formateDate(resultData.profile.born_date)}
                                </TableCell>
                              </TableRow>
                            ) : null}

                            <TableRow>
                              <TableCell>
                                <strong>Tipo de conta:</strong>
                              </TableCell>
                              <TableCell>{resultData.user.type}</TableCell>
                            </TableRow>

                            {resultData.user.type === "COMPANY" ? (
                              <TableRow>
                                <TableCell>
                                  <strong>Quantidade de colaboradores:</strong>
                                </TableCell>
                                <TableCell>
                                  {collaboratorsCount
                                    ? collaboratorsCount
                                    : "carregando..."}
                                </TableCell>
                              </TableRow>
                            ) : null}

                            {resultData.user.type === "COMPANY" ? (
                              <TableRow>
                                <TableCell>
                                  <strong>Link do Step one</strong>
                                </TableCell>
                                <TableCell
                                  style={{
                                    display: "flex",
                                    gap: "2px",
                                    flexDirection: "column",
                                  }}
                                >
                                  {resultData.profile.redirects &&
                                  resultData.profile.redirects.length > 0
                                    ? resultData.profile.redirects.find(
                                        (redirect) =>
                                          redirect.type === "STEPONE"
                                      )?.url
                                    : "Nenhum link configurado"}
                                  <ButtonLink onClick={openStepModal}>
                                    {resultData.profile.redirects &&
                                    resultData.profile.redirects.length > 0
                                      ? "Editar"
                                      : "Adicionar"}
                                  </ButtonLink>
                                </TableCell>
                              </TableRow>
                            ) : null}
                            <TableRow>
                              <TableCell>
                                <strong>Status da conta:</strong>
                              </TableCell>
                              <TableCell>{resultData.user.status}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Conta verificada:</strong>
                              </TableCell>
                              <TableCell>
                                {!!resultData.user.is_verified ? "Sim" : "Não"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>E-mail:</strong>
                              </TableCell>
                              <TableCell>{resultData.user.email}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Nome de usuário:</strong>
                              </TableCell>
                              <TableCell>{resultData.user.username}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Telefones:</strong>
                              </TableCell>
                              <TableCell>
                                {resultData.profile.phones.map(
                                  (phone, index) => (
                                    <ChipContainer>
                                      <ChipLabel key={index}>
                                        {phone.phone_number}
                                      </ChipLabel>
                                    </ChipContainer>
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Data de criação da conta:</strong>
                              </TableCell>
                              <TableCell>
                                {formateDate(
                                  resultData.user.created_at.split("T")[0]
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>PROFILE_ID:</strong>
                              </TableCell>
                              <TableCell>{resultData.profile.id}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>USER_ID:</strong>
                              </TableCell>
                              <TableCell>{resultData.user.id}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </SingleResult>
                    ) : null}

                    {selectedTab === "EMAIL_LOG" ? (
                      <EmailsLog email={resultData.user.email} />
                    ) : null}
                  </GridCol>
                ) : null}
              </GridRow>
            </GridContainer>
          </WhiteContainer>
        </GridContainer>
      </Layout>

      {autentiqueModal && resultData ? (
        <AutentiqueModal
          open={autentiqueModal}
          setOpen={setAutentiqueModal}
          setAutentiqueApiKey={setAutentiqueApiKey}
          companyId={resultData.profile.id}
          autentiqueApiKey={autentiqueApiKey}
        />
      ) : null}
      {/* {linkStepModal && resultData ? (
        <LinkRedirectModal
          open={linkStepModal}
          setOpen={setLinkStepModal}
          companyId={resultData.profile.id}
          result={resultData}
          updateResult={updateResultData}
        />
      ) : null} */}

      {whiteLabelModal && resultData ? (
        <WhiteLabelModal
          companyId={resultData.profile.id}
          open={whiteLabelModal}
          setOpen={setWhiteLabelModal}
        />
      ) : null}
    </>
  );
};

export default SearchUser;
