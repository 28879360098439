import React, { useEffect, useState } from "react";
import { Button, OutlinedButton } from "components/Buttons/styles";
import Input from "components/Forms/Input";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import ModalLoadingOverlay from "components/Modal/ModalLoadingOverlay";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { Label } from "components/Forms/Label";
import { FieldWrapped } from "components/Forms/FieldWrapped";
import SelectAutocomplete, {
  AutoCompleteOptions,
} from "components/Forms/SelectAutocomplete";
import { LevelItemProps } from "interfaces/Level";
import {
  LevelFormData,
  getLevels,
  saveLevel,
  updateLevel,
} from "requests/levels";
import LevelIcon from "components/Modal/icons/LevelIcon";
import { HelperText } from "components/Forms/Helpers/HelperText";

interface LevelFormProps {
  setLevelList: React.Dispatch<React.SetStateAction<LevelItemProps[]>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: "add" | "edit";
  dataEdit?: any;
}

const maxCharacters = {
  name: 100,
};

const schema = yup.object({
  name: yup
    .string()
    .required("Campo obrigatório")
    .max(maxCharacters.name, "Máximo de caracteres: 100"),
  bellow_level_id: yup
    .string()
    .nullable() // Permitir que seja nulo
    .test("unique", "Campos não podem ser iguais", function (value) {
      const { above_level_id, pair_level_id } = this.parent;
      if (value && (value === above_level_id || value === pair_level_id)) {
        return false;
      }
      return true;
    }),

  above_level_id: yup
    .string()
    .nullable() // Permitir que seja nulo
    .test("unique", "Campos não podem ser iguais", function (value) {
      const { bellow_level_id, pair_level_id } = this.parent;
      if (value && (value === bellow_level_id || value === pair_level_id)) {
        return false;
      }
      return true;
    }),

  pair_level_id: yup
    .string()
    .nullable() // Permitir que seja nulo
    .test("unique", "Campos não podem ser iguais", function (value) {
      const { bellow_level_id, above_level_id } = this.parent;
      if (value && (value === bellow_level_id || value === above_level_id)) {
        return false;
      }
      return true;
    }),
});

const convertForAutocomplete = (data: LevelItemProps[]) =>
  data.map((item) => ({
    value: item.id,
    label: item.name,
  }));

const LevelForm: React.FC<LevelFormProps> = ({
  type,
  open,
  setOpen,
  setLevelList,
  dataEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [levelOptions, setLevelOptions] = useState<AutoCompleteOptions[]>([]);

  const methods = useForm<LevelFormData>({
    //resolver: yupResolver(schema),
  });

  useEffect(() => {
    getLevels(1, 99999).then((response) => {
      const newData = convertForAutocomplete(response.data);
      setLevelOptions(newData);
    });
  }, []);

  // AUTOCOMPLETE DEFAULT VALUES
  const bellowDefault =
    type === "edit" && dataEdit?.bellow
      ? {
          label: dataEdit.bellow.name,
          value: dataEdit.bellow.id,
        }
      : null;

  const aboveDefault =
    type === "edit" && dataEdit?.above
      ? {
          label: dataEdit.above.name,
          value: dataEdit.above.id,
        }
      : null;

  const pairDefault =
    type === "edit" && dataEdit?.pair
      ? {
          label: dataEdit.pair.name,
          value: dataEdit.pair.id,
        }
      : null;

  // END AUTOCOMPLETE DEFAULT VALUES

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: LevelFormData) => {
    if (
      data.above_level_id &&
      data.bellow_level_id &&
      data.above_level_id === data.bellow_level_id
    ) {
      methods.setError("above_level_id", {
        message: "Você não pode utilizar o mesmo nível em mais de um campo.",
      });

      methods.setError("bellow_level_id", {
        message: "Você não pode utilizar o mesmo nível em mais de um campo.",
      });

      return;
    }

    if (
      data.bellow_level_id &&
      data.above_level_id &&
      data.bellow_level_id === data.above_level_id
    ) {
      methods.setError("above_level_id", {
        message: "Você não pode utilizar o mesmo nível em mais de um campo.",
      });
      return;
    }

    setLoading(true);

    if (type === "add") {
      const newData = {
        ...data,
        bellow_level_id: data.bellow_level_id ? data.bellow_level_id : null,
        above_level_id: data.above_level_id ? data.above_level_id : null,
        pair_level_id: data.pair_level_id ? data.pair_level_id : null,
      };

      saveLevel(newData).then((response) => {
        const newLevel = { isNew: true, ...response };
        setLevelList((old) => [newLevel, ...old]);
        setLoading(false);
        setOpen(false);
      });
    } else {
      const newData = {
        ...data,
        bellow_level_id: data.bellow_level_id ? data.bellow_level_id : null,
        above_level_id: data.above_level_id ? data.above_level_id : null,
        pair_level_id: data.pair_level_id ? data.pair_level_id : null,
      };

      updateLevel(dataEdit.id, newData).then((updatedSector) => {
        setLevelList((old) =>
          old.map((sector) =>
            sector.id === updatedSector.id ? updatedSector : sector
          )
        );
        setLoading(false);
        setOpen(false);
      });
    }
  };

  return (
    <Modal
      open={open}
      title={type === "add" ? "Cadastrar nível" : "Editar nível"}
      onClose={handleClose}
      icon={<LevelIcon />}
    >
      {loading ? <ModalLoadingOverlay /> : null}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalContent>
            <GridContainer>
              <GridRow>
                <GridCol xs={12} sm={12} lg={12}>
                  <Input
                    required={true}
                    label="Nome do nível"
                    limit={maxCharacters.name}
                    inputProps={{
                      ...methods.register("name"),
                      defaultValue:
                        type === "edit" && dataEdit ? dataEdit.name : "",
                    }}
                    error={methods.formState.errors.name}
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol xs={12} sm={6} lg={6}>
                  <FieldWrapped>
                    <Label>Está abaixo de:</Label>
                    <SelectAutocomplete
                      defaultValue={bellowDefault}
                      name="bellow_level_id"
                      options={levelOptions}
                    />
                    {methods.formState.errors.bellow_level_id ? (
                      <HelperText color="danger">
                        {methods.formState.errors.bellow_level_id.message}
                      </HelperText>
                    ) : null}
                  </FieldWrapped>
                </GridCol>
                <GridCol xs={12} sm={6} lg={6}>
                  <FieldWrapped>
                    <Label>Está acima de:</Label>
                    <SelectAutocomplete
                      defaultValue={aboveDefault}
                      name="above_level_id"
                      options={levelOptions}
                    />
                    {methods.formState.errors.above_level_id ? (
                      <HelperText color="danger">
                        {methods.formState.errors.above_level_id.message}
                      </HelperText>
                    ) : null}
                  </FieldWrapped>
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol xs={12} sm={12} lg={12}>
                  <FieldWrapped>
                    <Label>Ou é par com:</Label>
                    <SelectAutocomplete
                      defaultValue={pairDefault}
                      name="pair_level_id"
                      options={levelOptions}
                    />
                    {methods.formState.errors.pair_level_id ? (
                      <HelperText color="danger">
                        {methods.formState.errors.pair_level_id.message}
                      </HelperText>
                    ) : null}
                  </FieldWrapped>
                </GridCol>
              </GridRow>
            </GridContainer>
          </ModalContent>
          <ModalActions>
            <OutlinedButton size="small" onClick={handleClose}>
              Cancelar
            </OutlinedButton>
            <Button size="small" type="submit">
              {type === "add" ? "Salvar nível" : "Atualizar nível"}
            </Button>
          </ModalActions>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default LevelForm;
