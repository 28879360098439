import React, { ReactNode, useState } from "react";
import { Label } from "../Label";
import { FieldWrapped } from "../FieldWrapped";
import { SelectBase, SelectWrapped } from "./Select";

interface SelectProps {
  label?: string;
  required?: boolean;
  children: ReactNode;
  inputProps?: React.SelectHTMLAttributes<HTMLSelectElement>;
}

const Select: React.FC<SelectProps> = ({
  label,
  required,
  children,
  inputProps,
}) => {
  const isRequired = required !== undefined ? required : false;

  return (
    <FieldWrapped>
      {label && (
        <Label>
          {label}
          {isRequired && (
            <span title="Campo obrigatório" style={{ color: "#D40000" }}>
              *
            </span>
          )}
        </Label>
      )}

      <SelectBase {...inputProps} required={isRequired}>
        {children}
      </SelectBase>
    </FieldWrapped>
  );
};

export default Select;
