import React, { useState } from "react";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import { FieldWrapped } from "components/Forms/FieldWrapped";
import { Label } from "components/Forms/Label";
import {
  SelectContainer,
  selectCustomStyles,
} from "components/Forms/SelectAutocomplete";
import Radio, { RadioGroup } from "components/Forms/Radio";
import { Button, OutlinedButton } from "components/Buttons/styles";
import AsyncCreatableSelect from "react-select/async-creatable";
import { StudyAreaItemProps } from "interfaces/Job";
import { getStudyAreas, saveStudyArea } from "requests/jobs";
import { KnowledgeAreas, formationAcademicLevels } from "utils/lists";
import { FormationItemProps } from "..";
import Select from "react-select";
import FormationIcon from "components/Modal/icons/FormationIcon";
import { HelperText } from "components/Forms/Helpers/HelperText";

interface FormationModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataEdit?: any;
  setFormationList: React.Dispatch<React.SetStateAction<FormationItemProps[]>>;
}

interface AreaStudyOption {
  readonly value: number;
  readonly label: string;
}

const convertForAutocomplete = (data: StudyAreaItemProps[]) =>
  data.map((item) => ({
    value: item.id,
    label: item.name,
  }));

const FormationModal: React.FC<FormationModalProps> = ({
  open,
  setOpen,
  dataEdit,
  setFormationList,
}) => {
  const [typeEvaluation, setTypeEvaluation] = useState<
    "REQUIRED" | "DIFFERENTIAL"
  >("DIFFERENTIAL");
  const [options, setOptions] = useState<any>([]);
  const [knwonledgeArea, setKnownlegdeArea] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [studyAreaSelected, setStudyAreaSelected] =
    useState<AreaStudyOption | null>(null);
  const [levelSelected, setLevelSelected] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [createAreaLoading, setCreateAreaLoading] = useState(false);
  const [levelError, setLevelError] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setTypeEvaluation(e.target.value);
  };

  const loadOptions = (inputValue: string, callback: any) => {
    if (inputValue.length < 4) {
      callback([]);
      return;
    }

    /*
    getStudyAreas(inputValue).then((response) => {
      const options = convertForAutocomplete(response.data);
      setOptions(options);
      callback(options);
    });
    */
  };

  const handleSubmit = () => {
    if (!levelSelected) {
      setLevelError(true);
      return;
    }

    const newData: FormationItemProps = {
      level: levelSelected ?? null,
      knowledge_area: knwonledgeArea ?? null,
      study_area: studyAreaSelected ?? null,
      type_evaluation: typeEvaluation.toUpperCase() as "REQUIRED" | "DIFFERENTIAL",
    };

    setFormationList((old) => [newData, ...old]);
    setOpen(false);
  };

  const handleCreateArea = (inputValue: string) => {
    setCreateAreaLoading(true);
    saveStudyArea(inputValue).then(
      (response: StudyAreaItemProps) => {
        const newData = {
          value: response.id,
          label: response.name,
        };

        setStudyAreaSelected(newData);
        setCreateAreaLoading(false);
      }
    );
  };

  return (
    <Modal
      open={open}
      title={dataEdit ? "Editar formação" : "Adicionar formação ao cargo"}
      onClose={handleClose}
      icon={<FormationIcon />}
    >
      <ModalContent>
        <GridContainer>
          <GridRow>
            <GridCol xs={12} sm={6} lg={6}>
              <FieldWrapped>
                <Label required={true}>Nível</Label>
                <Select
                  isClearable={true}
                  isSearchable={true}
                  options={formationAcademicLevels}
                  styles={selectCustomStyles}
                  menuPortalTarget={document.body}
                  menuShouldScrollIntoView={false}
                  menuPlacement="bottom"
                  placeholder=""
                  noOptionsMessage={() => "Nenhuma opção encontrada"}
                  onChange={(selectedOption: any) => {
                    setLevelSelected(selectedOption);
                    setLevelError(false);
                  }}
                />
                {levelError && (
                  <HelperText color="danger">Campo obrigatório</HelperText>
                )}
              </FieldWrapped>
            </GridCol>
            <GridCol xs={12} sm={6} lg={6}>
              <FieldWrapped>
                <Label>Área de conhecimento</Label>
                <SelectContainer>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    options={KnowledgeAreas}
                    styles={selectCustomStyles}
                    menuPortalTarget={document.body}
                    menuShouldScrollIntoView={false}
                    menuPlacement="bottom"
                    placeholder=""
                    noOptionsMessage={() => "Nenhuma opção encontrada"}
                    onChange={(selectedOption: any) =>
                      setKnownlegdeArea(selectedOption)
                    }
                  />
                </SelectContainer>
              </FieldWrapped>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol xs={12} sm={12} lg={12}>
              <RadioGroup direction="row">
                <Radio
                  label="Diferencial"
                  value="DIFFERENTIAL"
                  checked={typeEvaluation === "DIFFERENTIAL"}
                  onChange={handleChange}
                />
                <Radio
                  label="Obrigatório"
                  value="REQUIRED"
                  checked={typeEvaluation === "REQUIRED"}
                  onChange={handleChange}
                />
              </RadioGroup>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol xs={12} sm={12} lg={12}>
              <FieldWrapped>
                <Label>Área de estudo</Label>
                <SelectContainer>
                  <AsyncCreatableSelect
                    placeholder=""
                    defaultOptions
                    loadOptions={loadOptions}
                    allowCreateWhileLoading
                    isClearable={true}
                    menuPortalTarget={document.body}
                    menuShouldScrollIntoView={false}
                    menuPlacement="bottom"
                    noOptionsMessage={() =>
                      "Digite 4 caracteres para pesquisar"
                    }
                    value={studyAreaSelected}
                    styles={selectCustomStyles}
                    formatCreateLabel={(inputValue: string) =>
                      `Criar "${inputValue.slice(0, 150)}"`
                    }
                    isValidNewOption={(inputValue: string) =>
                      options.length === 0 && inputValue.length >= 4
                        ? true
                        : false
                    }
                    onChange={(value: AreaStudyOption | null) =>
                      setStudyAreaSelected(value)
                    }
                    onCreateOption={handleCreateArea}
                    isLoading={createAreaLoading}
                  />
                </SelectContainer>
              </FieldWrapped>
            </GridCol>
          </GridRow>
        </GridContainer>
      </ModalContent>
      <ModalActions>
        <OutlinedButton size="small" onClick={() => setOpen(false)}>
          Cancelar
        </OutlinedButton>
        <Button size="small" onClick={handleSubmit}>
          Adicionar
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default FormationModal;
