import { links } from "app/config/links";
import { SectorListProps } from "interfaces/Sector";
import api from "services/api";

export const getSectors = async (
  page: number,
  perPage: number
) => {
  const response = await api.get(
    links.api.core + `/engineering/master/sectors?page=${page}&perPage=${perPage}`,
  );

  return response.data as SectorListProps;
};

export const deleteSector = async (id: string) => {
  const response = await api.delete(
    links.api.core + `/engineering/master/sectors/${id}`,
  );
  return response;
};

interface SectorFormData {
  name: string;
  description: string;
  cost_center: string | null;
  responsible_id: string | null;
}

export interface ResponseSector extends SectorFormData {
  id: string;
  responsible: {
    id: string;
    person: {
      id: string;
      avatar: string;
      name: string;
    };
  } | null;
}

export const updateSector = async (
  id: string,
  data: SectorFormData,
) => {
  const response = await api.put(
    links.api.core + `/engineering/master/sectors/${id}`,
    data,
  );

  return response.data;
};

export const saveSector = async (data: SectorFormData) => {
  const response = await api.post(
    links.api.core + `/engineering/master/sectors`,
    data,
  );

  return response.data;
};

export const showSector = async (sectorId: string) => {
  const response = await api.get(
    links.api.core + `/engineering/master/sectors/${sectorId}`,
  );

  return response.data as ResponseSector;
};
