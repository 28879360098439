import React, { useEffect, useState } from "react";
import { Label } from "../Label";
import { InputBase } from "./Input";
import { FieldWrapped } from "../FieldWrapped";
import { CharacterCounter } from "../CharacterCounter";
import { FieldError } from "react-hook-form";
import { HelperText } from "../Helpers/HelperText";

interface InputProps {
  label?: string;
  required: boolean;
  limit: number;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  error?: FieldError | undefined;
}

const Input: React.FC<InputProps> = ({
  label,
  required,
  limit,
  inputProps,
  error,
}) => {
  const [charCount, setCharCount] = useState(
    inputProps && inputProps.defaultValue
      ? inputProps.defaultValue.toString().length
      : 0
  );

  useEffect(() => {
    if (inputProps && inputProps.defaultValue) {
      setCharCount(inputProps.defaultValue.toString().length);
    }
  }, [inputProps?.defaultValue]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setCharCount(event.currentTarget.value.length);
  };

  return (
    <>
      <FieldWrapped>
        {label && <Label required={required}>{label}</Label>}

        <InputBase
          maxLength={limit}
          hasError={error !== undefined ? Boolean(error) : false}
          {...inputProps}
          onInput={handleChange}
        />

        {limit && (
          <CharacterCounter>
            {charCount}/{limit}
          </CharacterCounter>
        )}
      </FieldWrapped>
      {error ? <HelperText color="danger">{error.message}</HelperText> : null}
    </>
  );
};

export default Input;
