import styled from "styled-components";

export const Chip = styled.small`
  font-size: "9px !important";
  padding: 2px 4px;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  border-radius: 2px;
`;
