const AddSectorIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_1445_67704" fill="white">
        <path d="M12 0.500001C9.62662 0.500001 7.30655 1.20379 5.33316 2.52237C3.35977 3.84094 1.8217 5.71509 0.913445 7.9078C0.00519337 10.1005 -0.232447 12.5133 0.230576 14.8411C0.693599 17.1689 1.83649 19.3071 3.51472 20.9853C5.19295 22.6635 7.33114 23.8064 9.65892 24.2694C11.9867 24.7324 14.3995 24.4948 16.5922 23.5866C18.7849 22.6783 20.6591 21.1402 21.9776 19.1668C23.2962 17.1935 24 14.8734 24 12.5L12 12.5L12 0.500001Z" />
      </mask>
      <path
        d="M12 0.500001C9.62662 0.500001 7.30655 1.20379 5.33316 2.52237C3.35977 3.84094 1.8217 5.71509 0.913445 7.9078C0.00519337 10.1005 -0.232447 12.5133 0.230576 14.8411C0.693599 17.1689 1.83649 19.3071 3.51472 20.9853C5.19295 22.6635 7.33114 23.8064 9.65892 24.2694C11.9867 24.7324 14.3995 24.4948 16.5922 23.5866C18.7849 22.6783 20.6591 21.1402 21.9776 19.1668C23.2962 17.1935 24 14.8734 24 12.5L12 12.5L12 0.500001Z"
        fill="#008AC1"
        stroke="black"
        stroke-width="2.13115"
        mask="url(#path-1-inside-1_1445_67704)"
      />
      <mask id="path-2-inside-2_1445_67704" fill="white">
        <path d="M24.0003 10.3361C24.0003 9.04437 23.7458 7.76533 23.2515 6.57197C22.7572 5.3786 22.0327 4.29428 21.1193 3.38092C20.206 2.46755 19.1217 1.74303 17.9283 1.24873C16.7349 0.754417 15.4559 0.5 14.1642 0.5V10.3361H24.0003Z" />
      </mask>
      <path
        d="M24.0003 10.3361C24.0003 9.04437 23.7458 7.76533 23.2515 6.57197C22.7572 5.3786 22.0327 4.29428 21.1193 3.38092C20.206 2.46755 19.1217 1.74303 17.9283 1.24873C16.7349 0.754417 15.4559 0.5 14.1642 0.5V10.3361H24.0003Z"
        fill="#003B5D"
        stroke="black"
        stroke-width="1.70492"
        mask="url(#path-2-inside-2_1445_67704)"
      />
    </svg>
  );
};

export default AddSectorIcon;
