import { ActionTrashCell } from "components/Tables/styles";
import { CompanyData } from "interfaces/Companies";
import React from "react";
import { ReactComponent as Invalid } from "../../../../layout/icons/invalid.svg";

interface CompanyActionTrashCellProps {
  company: CompanyData;
  onClick: () => void;
}

const CompanyActionTrashCell: React.FC<CompanyActionTrashCellProps> = ({
  company,
  onClick,
}) => {
  const handleClick = () => onClick();

  return (
    <ActionTrashCell
      onClick={handleClick}
      exceeded={
        company.number_collaborators_linked > company.number_collaborators
      }
    >
      {`${company.number_collaborators_linked}/${company.number_collaborators}`}
      {company.number_collaborators_linked > company.number_collaborators && (
        <Invalid />
      )}
    </ActionTrashCell>
  );
};

export default CompanyActionTrashCell;
