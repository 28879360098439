import * as XLSX from "xlsx";
export function currency(e: React.FormEvent<HTMLInputElement>) {
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  e.currentTarget.value = value;
  return e;
}

export function xlsxToJson(file: File, callback: Function): any {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = () => {
    const data = new Uint8Array(reader.result as ArrayBuffer);
    const arr: Array<string> = [];
    for (let i = 0; i !== data.length; i += 1)
      arr[i] = String.fromCharCode(data[i]);
    const workbook = XLSX.read(arr.join(""), {
      type: "binary",
      cellDates: true,
      dateNF: "yyyy-MM-dd;@",
    });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    callback(
      XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        defval: "",
        dateNF: "yyyy-MM-dd",
      })
    );
  };
  reader.onerror = (err) => callback(err);
}

export const regexToValidateEmails = new RegExp(
  /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,6})+)$/
);

export function validateCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, "");

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.charAt(i - 1)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.charAt(9))) {
    return false;
  }

  soma = 0;

  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.charAt(i - 1)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.charAt(10))) {
    return false;
  }

  return true;
}

export const convertStringToInt = (str: string) => {
  const cleanStr = str.replace(/[^0-9]/g, "");
  const intValue = parseInt(cleanStr, 10);

  return intValue;
};

export const convertToReal = (salary: number) => {
  const formatedValue = (salary / 100).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatedValue;
};
