import { GridContainer } from "layout/components/page-styles";
import Layout from "layout/Layout";
import React, { useEffect, useState } from "react";
import UserViewHeader, {
  UserViewHeaderProps,
} from "./components/UserViewHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  StatusMenu,
  StatusMenuItem,
} from "pages/Dashboard/CompaniesList/styles";
import General from "./General";
import EmailsLog from "./EmailsLog";
import Collaborators from "./Collaborators";

type UserViewTabsType = "GENERAL" | "ACCOUNT" | "EMAILS_LOG" | "COLLABORATORS";

const UserView: React.FC = () => {
  const location = useLocation();
  const { state } = location || {};
  const [selectedTab, setSelectedTab] = useState<UserViewTabsType>("GENERAL");

  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/users/search");
    }
  }, [state, navigate]);

  const handleTab = (tab: UserViewTabsType) => {
    setSelectedTab(tab);
  };

  if (!state) return <></>;

  const headerData: UserViewHeaderProps["data"] = {
    avatar: state.profile.avatar,
    name: state.profile.name,
    email: state.user.email,
    username: state.user.username,
    status: state.user.status,
    accountType: state.user.type,
  };

  return (
    <Layout>
      <GridContainer style={{ marginBottom: "16px" }}>
        <UserViewHeader data={headerData} />
      </GridContainer>

      <GridContainer
        style={{
          marginBottom: "4px",
        }}
      >
        <StatusMenu
          style={{ minHeight: "40px", overflowX: "auto", overflowY: "hidden" }}
        >
          <StatusMenuItem
            onClick={() => handleTab("GENERAL")}
            className={selectedTab === "GENERAL" ? "active" : ""}
          >
            Geral
          </StatusMenuItem>

          {state.user.type === "COMPANY" && (
            <StatusMenuItem
              onClick={() => handleTab("COLLABORATORS")}
              className={selectedTab === "COLLABORATORS" ? "active" : ""}
            >
              Colaboradores
            </StatusMenuItem>
          )}

          <StatusMenuItem
            onClick={() => handleTab("EMAILS_LOG")}
            className={selectedTab === "EMAILS_LOG" ? "active" : ""}
          >
            Logs de E-mails
          </StatusMenuItem>
            

          {/* <StatusMenuItem
            onClick={() => handleTab("ACCOUNT")}
            className={selectedTab === "ACCOUNT" ? "active" : ""}
          >
            Conta
          </StatusMenuItem> */}
        </StatusMenu>
      </GridContainer>

      {selectedTab === "GENERAL" && <General data={state} />}
      {selectedTab === "EMAILS_LOG" && <EmailsLog email={state.user.email} />}
      {selectedTab === "COLLABORATORS" && (
        <Collaborators companyId={state.profile.id} />
      )}
    </Layout>
  );
};

export default UserView;
