import React, { useEffect, useState } from "react";
import Select, { MenuPlacement } from "react-select";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";

export const SelectContainer = styled.div`
  z-index: 1; /* Garante que o container fique acima dos outros elementos da página */
`;

export const selectCustomStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "#008ac1" : "rgb(175, 175, 175)",
    fontSize: "14px",
    "&:focus": {
      borderColor: "#008ac1",
      boxShadow: "0 0 0 1px #008ac1",
    },
    "&:focus-within": {
      borderColor: "#008ac1",
      boxShadow: "0 0 0 1px #008ac1",
    },
    "&:focus-visible": {
      borderColor: "#008ac1",
      boxShadow: "0 0 0 1px #008ac1",
    },
  }),
};

export interface AutoCompleteOptions {
  label: string;
  value: string;
}

interface AutocompleteProps {
  name: string;
  defaultValue?: {
    value: string;
    label: string;
  } | null;
  options: Array<AutoCompleteOptions>;
  value?: string;
  menuPlacement?: MenuPlacement | undefined;
  disabled?: boolean;
}

const SelectAutocomplete: React.FC<AutocompleteProps> = ({
  name,
  options,
  defaultValue,
  menuPlacement,
  disabled,
}) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue.value);
    }
  }, []);

  return (
    <SelectContainer>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            isDisabled={disabled}
            defaultValue={defaultValue ? defaultValue : null}
            isClearable={true}
            isSearchable={true}
            options={options}
            styles={selectCustomStyles}
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={false}
            menuPlacement={menuPlacement}
            placeholder=""
            value={options.find((option) => option.value === value)}
            noOptionsMessage={() => "Nenhuma opção encontrada"}
            onChange={(selectedOption) => {
              onChange(selectedOption?.value);
            }}
          />
        )}
      />
    </SelectContainer>
  );
};

export default SelectAutocomplete;
