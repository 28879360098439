import React, { useState } from "react";
import { Button, OutlinedButton } from "components/Buttons/styles";
import { InputBase } from "components/Forms/Input/Input";
import LoadingComponent from "components/LoadingComponent";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import CompaniesIcon from "components/Icons/CompaniesIcon";
import api from "services/api";
import { addToast } from "app/components/Toast";

interface AutentiqueModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  companyId: string;
  setAutentiqueApiKey: React.Dispatch<React.SetStateAction<string | null>>;
  autentiqueApiKey: string | null;
}

const AutentiqueModal: React.FC<AutentiqueModalProps> = ({ open, setOpen, companyId, setAutentiqueApiKey, autentiqueApiKey }) => {
  const [loading, setLoading] = useState(false);
  const [apiKeyValue, setApiKeyValue] = useState<null | string>(null);

  const handleClose = () => setOpen(false);

  const updateAutentiqueApi = async () => {
    const response = await api.post(`/companies/${companyId}/signature/`, {
      apiKey: apiKeyValue
    })

    return response.data
  }
  
  const handleAction = () => {
    setLoading(true)
    updateAutentiqueApi()
      .then((response) => {
        setAutentiqueApiKey(response.apiKey);
        handleClose();
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        addToast('Ocorreu um erro ao atualizar', { appearance: 'error' })
      })
  }

  return (
    <Modal
      icon={<CompaniesIcon />}
      open={open}
      onClose={handleClose}
      title="Integração Autentique"
    >
      {loading ? <LoadingComponent /> : null}
      <ModalContent>
        <p>Informe a API KEY do usuário na Autentique</p>
        <InputBase
          type="text"
          value={apiKeyValue ? apiKeyValue : undefined}
          onChange={(e) => setApiKeyValue(e.target.value)}
          placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
          defaultValue={autentiqueApiKey ?? ''}
        />
      </ModalContent>
      <ModalActions>
        <OutlinedButton size="small" onClick={handleClose}>Cancelar</OutlinedButton>
        <Button size="small" onClick={handleAction}>Atualizar</Button>
      </ModalActions>
    </Modal>
  )
}

export default AutentiqueModal;