import { addToast } from "app/components/Toast";
import { Button, IconButton, OutlinedButton } from "components/Buttons/styles";
import { FieldWrapped } from "components/Forms/FieldWrapped";
import Input from "components/Forms/Input";
import { Label } from "components/Forms/Label";
import LoadingComponent from "components/LoadingComponent";
import { WhiteContainer } from "components/WhiteContainer";
import { CompanyRegister, CompanyRegisterRequest } from "interfaces/Master";
import Layout from "layout/Layout";
import {
  GridCol,
  GridContainer,
  GridRow,
  HeaderPage,
} from "layout/components/page-styles";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputBase } from "components/Forms/Input/Input";
import { registerCompany } from "requests/master";
import { useNavigate } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import { HelperText } from "components/Forms/Helpers/HelperText";
import CompaniesIcon from "components/Icons/CompaniesIcon";
import { Title } from "./styles";

const RegisterCompany: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState<any>();

  const tempPassword = "senha1234";

  const schema = yup.object({
    name: yup
      .string()
      .required("Campo obrigatório")
      .min(4, "O campo deve ter no mínimo 4 caracteres"),
    legal_representative: yup
      .string()
      .required("Campo obrigatório")
      .min(4, "O campo deve ter no mínimo 4 caracteres"),
    partner_name: yup
      .string()
      .required("Campo obrigatório")
      .min(4, "O campo deve ter no mínimo 4 caracteres"),
    commercial_activity: yup
      .string()
      .required("Campo obrigatório")
      .min(4, "O campo deve ter no mínimo 4 caracteres"),
    number_collaborators: yup.string().required("Campo obrigatório"),
    phone: yup.string().required("Campo obrigatório"),
    email: yup.string().required("Campo obrigatório"),
    password: yup.string(),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CompanyRegister>({
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const copyPassword = () => {
    var campo = document.getElementById("password");
    var tempInput = document.createElement("input");
    // @ts-ignore
    tempInput.value = campo.value;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    addToast(`Senha copiada para a área de transferência.`, {
      appearance: "info",
    });
  };

  const onSubmit = (data: CompanyRegister) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("number_collaborators", data.number_collaborators);
    formData.append("commercial_activity", data.commercial_activity);
    formData.append("legal_representative", data.legal_representative);
    formData.append("email", data.email);
    formData.append("email_confirmation", data.email);
    formData.append("password", tempPassword);
    formData.append("password_confirmation", tempPassword);
    formData.append("timezone", "America/Sao_Paulo");
    formData.append("logo", logo?.length > 0 ? logo[0] : "");
    formData.append("partner_name", data.partner_name || "");
    formData.append("partner_id", "");

    registerCompany(formData)
      .then(() => {
        navigate("/dashboard");
        addToast(`Empresa cadastrada com sucesso.`, { appearance: "success" });
      })
      .catch((err) => {
        console.error(err);
        addToast(
          "Houve um erro inesperado. Atualize a página e tente novamente.",
          { appearance: "error" }
        );
        setLoading(false);
      });
  };

  return (
    <Layout>
      <GridContainer style={{ marginBottom: "16px" }}>
        <GridRow>
          <GridCol xs={12} md={12} lg={12}>
            <HeaderPage>
            <Title><CompaniesIcon />Cadastrar empresa</Title>
            </HeaderPage>
          </GridCol>
        </GridRow>
      </GridContainer>
      <GridContainer>
        <GridRow>
          <GridCol xs={12} sm={6} lg={6}>
            <WhiteContainer>
              <form onSubmit={handleSubmit(onSubmit)}>
                {loading ? <LoadingComponent /> : null}
                <GridContainer>
                  <GridRow>
                    <GridCol xs={12} sm={12} lg={12}>
                      <Input
                        limit={255}
                        required={true}
                        label="Nome da empresa"
                        error={errors.name}
                        inputProps={{ ...register("name") }}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol xs={12} sm={12} lg={12}>
                      <Input
                        limit={20}
                        required={true}
                        label="Nome do remetente"
                        inputProps={{
                          ...register("partner_name"),
                        }}
                        error={errors.partner_name}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol xs={12} sm={12} lg={12}>
                      <Input
                        limit={255}
                        required={true}
                        label="Representante"
                        inputProps={{
                          ...register("legal_representative"),
                        }}
                        error={errors.legal_representative}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol xs={12} sm={12} lg={12}>
                      <Input
                        limit={32}
                        required={true}
                        label="Ramo de atuação"
                        inputProps={{
                          ...register("commercial_activity"),
                        }}
                        error={errors.commercial_activity}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol xs={12} sm={12} lg={12}>
                      <Input
                        limit={255}
                        required={true}
                        label="E-mail"
                        inputProps={{ ...register("email"), type: "email" }}
                        error={errors.email}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol xs={12} sm={12} lg={12}>
                      <FieldWrapped>
                        <Label required={true}>Telefone</Label>
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field: { onChange, name, value } }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              onChange={onChange}
                              customInput={InputBase}
                              format="(##) #####-####"
                              mask="_"
                            />
                          )}
                        />
                        {errors.phone ? (
                          <HelperText color="danger">
                            {errors.phone.message}
                          </HelperText>
                        ) : null}
                      </FieldWrapped>
                      {/* <Input
                        limit={255}
                        required={true}
                        label="Telefone"
                        inputProps={{ ...register("phone") }}
                        error={errors.phone}
                      /> */}
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol xs={12} sm={12} lg={12}>
                      <Input
                        limit={255}
                        required={true}
                        label="Quantidade de colaboradores contratados"
                        inputProps={{ ...register("number_collaborators"), type: "number" }}
                        error={errors.number_collaborators}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol xs={12} sm={12} lg={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: "8px",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <FieldWrapped>
                            <Label>Senha</Label>
                            <InputBase
                              disabled={true}
                              id="password"
                              value={tempPassword}
                              {...register("password")}
                            />
                          </FieldWrapped>
                        </div>
                        <OutlinedButton
                          type="button"
                          size="small"
                          style={{ marginTop: "22px" }}
                          onClick={copyPassword}
                        >
                          Copiar
                        </OutlinedButton>
                      </div>
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol xs={12} sm={12} lg={12}>
                      <FieldWrapped>
                        <Label>Logo (whitelabel)</Label>
                        <InputBase
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={(e) => setLogo(e.target.files as any)}
                        />
                      </FieldWrapped>
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol xs={12} sm={12} lg={12}>
                      <Button type="submit">Cadastrar empresa</Button>
                    </GridCol>
                  </GridRow>
                </GridContainer>
              </form>
            </WhiteContainer>
          </GridCol>
        </GridRow>
      </GridContainer>
    </Layout>
  );
};

export default RegisterCompany;
