import CircularProgress from "components/CircularProgress";
import React from "react";

const ModalLoadingOverlay: React.FC = () => {
  return (
    <div
      style={{
        position: "absolute",
        background: "rgba(255, 255, 255, 0.6)",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 99,
      }}
    >
      <CircularProgress size={50} thickness={6} />
    </div>
  );
};

export default ModalLoadingOverlay;
