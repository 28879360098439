import React, { useState } from "react";
import {
  ActionsCell,
  TableCell,
  TableRow,
} from "../../../../components/Tables/styles";
import { MoreButton } from "../../../../components/Buttons/styles";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { ReactComponent as MoreIcon } from "../../../../layout/icons/more-icon.svg";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import ConfirmModal from "components/ConfirmModal";
import { Chip } from "components/Chip/Chip";
import { JobItemProps } from "interfaces/Job";
import { deleteJob } from "requests/jobs";
import { useNavigate } from "react-router-dom";

interface JobsProps {
  job: JobItemProps;
  setJobList: React.Dispatch<React.SetStateAction<JobItemProps[]>>;
  setSearchResults: React.Dispatch<React.SetStateAction<JobItemProps[]>>;
}

const JobItem: React.FC<JobsProps> = ({
  job,
  setJobList,
  setSearchResults,
}) => {
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  const navigate = useNavigate();

  const handleDelete = () => {
    setDeleteConfirmModal(true);
  };

  const handleDeleteJob = () => {
    deleteJob(job.id).then(() => {
      setDeleteConfirmModal(false);
      setJobList((old) => old.filter((item) => item.id !== job.id));
      setSearchResults((old) => old.filter((item) => item.id !== job.id));
    });
  };

  const handleEditModal = () => {
    navigate(`/records/jobs/${job.id}`);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          {job.name} {!!job.isNew ? <Chip>NOVO</Chip> : null}
        </TableCell>
        <ActionsCell>
          <Menu
            direction="left"
            menuButton={
              <MoreButton>
                <MoreIcon />
              </MoreButton>
            }
            transition
          >
            <MenuItem
              onClick={handleEditModal}
            >
              Editar
            </MenuItem>
            <MenuItem
              onClick={handleDelete}
            >
              Excluir
            </MenuItem>
          </Menu>
        </ActionsCell>
      </TableRow>

      {deleteConfirmModal ? (
        <ConfirmModal
          open={deleteConfirmModal}
          title="Excluir cargo"
          description={`Você tem certeza que deseja excluir o seguinte cargo: ${job.name}?`}
          setOpen={setDeleteConfirmModal}
          action={handleDeleteJob}
        />
      ) : null}
    </>
  );
};

export default JobItem;
