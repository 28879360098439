import styled from "styled-components";

interface LabelProps {
  required?: boolean;
}

export const Label = styled.label<LabelProps>`
  font-size: "16px";
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.text};

  ${({ required }) =>
    required &&
    `
      &::after {
        content: "*";
        color: red;
      }
    `}
`;
