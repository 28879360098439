import Avatar from "components/Avatar";
import { AccountTypeChip, CollaboratorAvatar, CollaboratorDescription, CollaboratorName, HeaderContainer, HeaderData, UserStatusChip } from "pages/UserView/styles";
import React from "react";

export interface UserViewHeaderProps {
  data: {
    avatar: string | null;
    name: string;
    email: string;
    username: string;
    status: "ACTIVE" | "BLOCKED"
    accountType: "COMPANY" | "PERSON"
  }
}

const UserViewHeader: React.FC<UserViewHeaderProps> = ({ data }) => {
  return (
    <HeaderContainer>
      <div>
        <CollaboratorAvatar
          isUnlinked={false}
        >
          <div>
            <Avatar
              name={data.name}
              src={data.avatar ?? ""}
              size="79px"
              fontSize="26px"
            />
          </div>
        </CollaboratorAvatar>
      </div>
      <HeaderData>
        <CollaboratorName>{data.name}</CollaboratorName>
        <CollaboratorDescription>
          @{data.username}
        </CollaboratorDescription>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            flexWrap: "wrap",
          }}
        >
          <UserStatusChip status={data.status}>
            {data.status === "ACTIVE" ? "ATIVO" : data.status}
          </UserStatusChip>

          <AccountTypeChip>
            {data.accountType === "COMPANY" ? "Conta empresa" : "Conta pessoal"}
          </AccountTypeChip>
        </div>
      </HeaderData>
    </HeaderContainer>
  );
};

export default UserViewHeader;
