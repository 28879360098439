import api from "services/api";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { TokenData } from "interfaces/TokenData";
import { decode } from "../app/utils/crypto";

export const useInterceptor = (
  logout: Function,
  refreshToken: Function
): void => {
  api.interceptors.request.use(
    async (config) => {
      if (config?.url?.includes("refresh-token")) return config;

      let token = Cookies.get("authToken");
      let isTokenExpiredOrAboutToExpire = false;

      if (token) {
        try {
          token = decode(token);

          const { exp }: TokenData = jwtDecode(token);

          const unixExpirationTimestamp = +new Date(exp * 1000);
          const unixCurrentTimestamp = +new Date();

          isTokenExpiredOrAboutToExpire =
            unixExpirationTimestamp - unixCurrentTimestamp <= 10;
        } catch (error) {
          return logout();
        }
      }

      if (!token || isTokenExpiredOrAboutToExpire) {
        const unauthenticatedRoutes = ["/"];
        const { pathname } = window.location;

        if (unauthenticatedRoutes.includes(pathname)) {
          return;
        }

        // token = await refreshToken();
        logout();
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      const token = Cookies.get("authToken");

      // if (error.response?.status === 401 && token) {
      //   return logout()
      // }

      return Promise.reject(error);
    }
  );
};
