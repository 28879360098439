import React, { useState } from "react";
import Warning from "components/Icons/Alerts/Warning";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import { Button, OutlinedButton } from "components/Buttons/styles";
import ModalLoadingOverlay from "components/Modal/ModalLoadingOverlay";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
    padding: "16px",
    maxWidth: "600px",
    width: "100%",
    border: "none",
    backgroundColor: "#fff",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
  },
};

interface ConfirmModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description: string;
  button?: string;
  action: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  title,
  description,
  setOpen,
  button,
  action,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = () => {
    setLoading(true);
    action();
  };

  return (
    <Modal icon={<Warning />} open={open} onClose={handleClose} title={title}>
      {loading ? <ModalLoadingOverlay /> : null}
      <ModalContent>
        <p>{description}</p>
      </ModalContent>
      <ModalActions>
        <OutlinedButton size="small" onClick={handleClose}>
          Cancelar
        </OutlinedButton>
        <Button size="small" onClick={handleAction}>
          {button ? button : "Confirmar"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default ConfirmModal;
