import React, { useEffect, useState } from "react";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import InsertCompetenceModalIcon from "components/Modal/icons/InsertCompetenceModalIcon";
import { Button, OutlinedButton } from "components/Buttons/styles";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import { FieldWrapped } from "components/Forms/FieldWrapped";
import { Label } from "components/Forms/Label";
import { SelectBase } from "components/Forms/Select/Select";
import { InputBase } from "components/Forms/Input/Input";
import { BehavioralProfileCompetenceTypes } from "utils/lists";
import { getCompetencesList } from "requests/jobs";
import { CompetenceInterface, CompetencesItemProps } from "interfaces/Job";
import * as yup from "yup";
import { useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HelperText } from "components/Forms/Helpers/HelperText";

interface BehavioralProfileModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allCompetencesList: CompetencesItemProps[];
  setSelectedCompetences: React.Dispatch<
    React.SetStateAction<CompetenceInterface[]>
  >;
}

const schema = yup.object({
  type: yup.string().required("Campo obrigatório"),
  competence_id: yup.string().required("Campo obrigatório"),
  level: yup
    .number()
    .required("Campo obrigatório")
    .typeError("Número inválido")
    .min(0, "Min: 0")
    .max(100, "Máx: 100"),
});

const BehavioralProfileModal: React.FC<BehavioralProfileModalProps> = ({
  open,
  setOpen,
  allCompetencesList,
  setSelectedCompetences,
}) => {
  const [competencesList, setCompetencesList] = useState<
    CompetencesItemProps[]
  >([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<CompetenceInterface>({
    resolver: yupResolver(schema),
  });
  const handleClose = () => setOpen(false);

  const handleTypeChange = (e) => {
    const updatedList = allCompetencesList.filter(
      (item) => item.type === Number(e.target.value)
    );
    setCompetencesList(updatedList);
    setValue("competence_id", "");
  };

  const onSubmit = (data) => {
    setSelectedCompetences((old) => [...old, data]);
    handleClose();
  };

  return (
    <Modal
      title="Inserir competência"
      onClose={handleClose}
      icon={<InsertCompetenceModalIcon />}
      open={open}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <GridContainer>
            <GridRow>
              <GridCol xs={12} sm={5} lg={5}>
                <FieldWrapped>
                  <Label required={true}>Tipo</Label>
                  <SelectBase {...register("type")} onChange={handleTypeChange}>
                    <option value="" disabled selected>
                      -- selecione um tipo --
                    </option>
                    {BehavioralProfileCompetenceTypes.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </SelectBase>
                  {errors.type && (
                    <HelperText color="danger">
                      {errors.type.message}
                    </HelperText>
                  )}
                </FieldWrapped>
              </GridCol>
              <GridCol xs={12} sm={5} lg={5}>
                <FieldWrapped>
                  <Label required={true}>Selecionar competência</Label>
                  <SelectBase
                    {...register("competence_id")}
                    disabled={Boolean(!competencesList?.length)}
                  >
                    <option disabled selected value="">
                      -- selecione a competência --
                    </option>
                    {competencesList.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </SelectBase>
                  {errors.competence_id && (
                    <HelperText color="danger">
                      {errors.competence_id.message}
                    </HelperText>
                  )}
                </FieldWrapped>
              </GridCol>
              <GridCol xs={12} sm={2} lg={2}>
                <FieldWrapped>
                  <Label required={true}>Nível</Label>
                  <InputBase {...register("level")} />
                  {errors.level && (
                    <HelperText color="danger">
                      {errors.level.message}
                    </HelperText>
                  )}
                </FieldWrapped>
              </GridCol>
            </GridRow>
          </GridContainer>
        </ModalContent>
        <ModalActions>
          <OutlinedButton type="button" size="small" onClick={handleClose}>
            Cancelar
          </OutlinedButton>
          <Button type="submit" size="small">
            Adicionar
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

export default BehavioralProfileModal;
