import React, { useEffect, useState } from "react";
import Layout from "../../../layout/Layout";
import { RecordsPage } from "../styles";
import {
  GridCol,
  GridContainer,
  GridRow,
  HeaderPage,
} from "../../../layout/components/page-styles";
import {
  Table,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "../../../components/Tables/styles";
import { Button } from "../../../components/Buttons/styles";
import SectorItem from "./SectorItem";
import { getSectors } from "requests/sectors";
import { SectorItemProps } from "interfaces/Sector";
import TablePagination from "components/TablePagination";
import SectorForm from "./SectorForm";
import {
  InputBase,
  SearchButton,
  SearchInputStyle,
} from "components/Forms/Input/Input";
import { ReactComponent as SearchIcon } from "../../../layout/icons/search.svg";
import { IconTitle } from "layout/styles";
import RecordsIcon from "../../../layout/icons/records.svg";

const Sectors: React.FC<unknown> = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sectorList, setSectorList] = useState<SectorItemProps[]>([]);
  const [searchResults, setSearchResults] = useState<SectorItemProps[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const totalRows = searchResults.length || sectorList.length;

  useEffect(() => {
    getSectors(1, 9999).then((response) => {
      setSectorList(response.data);
    });
  }, []);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const currentPageData =
    searchResults.length > 0
      ? searchResults.slice(startIndex, endIndex)
      : sectorList.slice(startIndex, endIndex);

  const handleSearch = (event) => {
    const query = event.target.value;
    const filteredList = sectorList.filter((sector) =>
      sector.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredList);
    setPage(1);
  };

  return (
    <>
      <Layout>
        <RecordsPage>
          <GridContainer style={{ marginBottom: "16px" }}>
            <GridRow>
              <GridCol xs={12} md={12} lg={12}>
                <HeaderPage>
                  <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <IconTitle src={RecordsIcon} width={28} />
                    <h1
                      style={{
                        fontWeight: "400",
                        color: "#535353",
                        fontSize: "24px",
                      }}
                    >
                      Setor
                    </h1>
                  </div>
                  <Button
                    onClick={() => setModalOpen(true)}
                  >
                    Cadastrar setor
                  </Button>
                </HeaderPage>
              </GridCol>
            </GridRow>
          </GridContainer>
          <GridContainer style={{ marginBottom: "16px" }}>
            <SearchInputStyle>
              <InputBase
                onChange={handleSearch}
                type="text"
                placeholder="Pesquisar..."
              />
              <SearchButton>
                <SearchIcon
                  style={{ width: "12px", height: "12px", fill: "#afafaf" }}
                />
              </SearchButton>
            </SearchInputStyle>
          </GridContainer>
          <GridContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Setor</TableHeaderCell>
                  <TableHeaderCell>Responsável</TableHeaderCell>
                  <TableHeaderCell style={{ textAlign: "right" }}>
                    Ações
                  </TableHeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {currentPageData.map((sector) => (
                  <SectorItem
                    key={sector.id}
                    sector={sector}
                    setSectorList={setSectorList}
                    setSearchResults={setSearchResults}
                  />
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPage={rowsPerPage}
              totalRows={totalRows}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </GridContainer>
        </RecordsPage>
      </Layout>

      {modalOpen && (
        <SectorForm
          setSectorList={setSectorList}
          open={modalOpen}
          setOpen={setModalOpen}
          type="add"
        />
      )}
    </>
  );
};

export default Sectors;
