import React, { useEffect, useState } from "react";
import Layout from "../../../layout/Layout";
import { RecordsPage } from "../styles";
import {
  GridCol,
  GridContainer,
  GridRow,
  HeaderPage,
} from "../../../layout/components/page-styles";
import {
  Table,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "../../../components/Tables/styles";
import { Button } from "../../../components/Buttons/styles";
import TablePagination from "components/TablePagination";
import {
  InputBase,
  SearchButton,
  SearchInputStyle,
} from "components/Forms/Input/Input";
import { ReactComponent as SearchIcon } from "../../../layout/icons/search.svg";
import { JobItemProps } from "interfaces/Job";
import { getJobs } from "requests/jobs";
import JobItem from "./JobItem";
import { useJobsContext } from "./context";
import { useNavigate } from "react-router-dom";
import RecordsIcon from "../../../layout/icons/records.svg";
import { IconTitle } from "layout/styles";

const Jobs: React.FC<unknown> = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchResults, setSearchResults] = useState<JobItemProps[]>([]);

  const { jobList, setJobList, newJobAdded, setNewJobAdded } = useJobsContext();

  const totalRows = searchResults.length || jobList.length;

  useEffect(() => {
    if (!newJobAdded) {
      getJobs(1, 9999).then((response) => {
        setJobList(response.data);
      });

      setNewJobAdded(false);
    }
  }, []);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const currentPageData =
    searchResults.length > 0
      ? searchResults.slice(startIndex, endIndex)
      : jobList.slice(startIndex, endIndex);

  const handleSearch = (event) => {
    const query = event.target.value;
    const filteredList = jobList.filter((level) =>
      level.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredList);
    setPage(1);
  };

  return (

    <Layout>
      <RecordsPage>
        <GridContainer style={{ marginBottom: "16px" }}>
          <GridRow>
            <GridCol xs={12} md={12} lg={12}>
              <HeaderPage>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <IconTitle src={RecordsIcon} width={28} />
                  <h1
                    style={{
                      fontWeight: "400",
                      color: "#535353",
                      fontSize: "24px",
                    }}
                  >
                    Cargos
                  </h1>
                </div>
                <Button
                  onClick={() => navigate("/records/jobs/register")}
                >
                  Cadastrar cargo
                </Button>
              </HeaderPage>
            </GridCol>
          </GridRow>
        </GridContainer>
        <GridContainer style={{ marginBottom: "16px" }}>
          <SearchInputStyle>
            <InputBase
              onChange={handleSearch}
              type="text"
              placeholder="Pesquisar..."
            />
            <SearchButton>
              <SearchIcon
                style={{ width: "12px", height: "12px", fill: "#afafaf" }}
              />
            </SearchButton>
          </SearchInputStyle>
        </GridContainer>
        <GridContainer style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Cargo</TableHeaderCell>
                <TableHeaderCell style={{ textAlign: "right" }}>
                  Ações
                </TableHeaderCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentPageData.map((job) => (
                <JobItem
                  key={job.id}
                  job={job}
                  setJobList={setJobList}
                  setSearchResults={setSearchResults}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </GridContainer>
      </RecordsPage>
    </Layout>
  );
};

export default Jobs;
