import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  border-bottom: 2px solid #d2d2d2;
  padding-bottom: 15px;
`;

export const HeaderData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
`;

interface CollaboratorAvatarProps {
  isUnlinked: boolean;
}

export const CollaboratorAvatar = styled.div<CollaboratorAvatarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px !important;
  img {
    filter: ${({ isUnlinked }) =>
    isUnlinked ? "grayscale(1)" : "grayscale(0)"};
  }
`;

export const CollaboratorName = styled.h3`
  font-size: 26px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: #545454;
  line-height: 1;
`;

export const CollaboratorDescription = styled.h4`
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #545454;
  line-height: 1;
`;

interface UserStatusProps {
  status: "ACTIVE" | "BLOCKED";
}

export const UserStatusChip = styled.span<UserStatusProps>`
  background: ${({ theme, status }) =>
    status === "ACTIVE" ? theme.colors.success : theme.colors.danger};
  color: #ffffff;
  padding: 3px 16px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #00A424;
`;

export const AccountTypeChip = styled.span`
  background: rgb(0, 164, 229);
  color: #ffffff;
  padding: 3px 16px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid rgb(0, 164, 229);
`;