import React, { useEffect, useState } from "react";
import Layout from "layout/Layout";
import {
  GridCol,
  GridContainer,
  GridRow,
  HeaderPage,
} from "layout/components/page-styles";
import {
  InputBase,
  SearchButton,
  SearchInputStyle,
} from "components/Forms/Input/Input";
import { ReactComponent as SearchIcon } from "../../../layout/icons/search.svg";
import { ReactComponent as CompaniesIcon } from "../../../layout/icons/list-companies.svg";
import {
  CompaniesContainer,
  StatusMenu,
  StatusMenuItem,
  Title,
} from "./styles";

import CompaniesTable from "./CompaniesTable";
import { CompanyData } from "interfaces/Companies";
import LoadingComponent from "components/LoadingComponent";
import TablePagination from "components/TablePaginationByRequest";
import { getAllCompanies } from "requests/master";
import { Button } from "components/Buttons/styles";
import { useNavigate } from "react-router-dom";

type TabsType = "ALL" | "EXCEEDED";

const CompaniesList: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [allCompanies, setAllCompanies] = useState<CompanyData[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedTab, setSelectedTab] = useState<TabsType>("ALL");

  const navigate = useNavigate();

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);

    const filters = selectedTab === "EXCEEDED" ? ["limit_exceeded"] : [];

    getAllCompanies(page, searchTerm, filters).then((response) => {
      const { data, meta } = response;
      const { current_page, total } = meta;
      setTotalPages(total);
      setAllCompanies(data);
      setPage(current_page);
      setLoading(false);
    });
  }, [page, searchTerm, selectedTab]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchTerm(query);
    setPage(1);
  };

  const handleChangeTab = (tab: TabsType) => {
    setSelectedTab(tab);
  };

  const handleRegisterCompany = () => {
    navigate(`/companies/register`);
  }

  return (
    <>
      <Layout>
        <GridContainer style={{ marginBottom: "16px" }}>
          <GridRow>
            <GridCol xs={12} md={12} lg={12}>
              <HeaderPage>
                <Title>
                  <CompaniesIcon /> Listar empresas
                </Title>
              </HeaderPage>
            </GridCol>
          </GridRow>
        </GridContainer>
        <GridContainer>
          <CompaniesContainer>
            <GridContainer>
              <GridRow>
                <GridCol
                  xs={12}
                  sm={4}
                  lg={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StatusMenu>
                    <StatusMenuItem
                      className={selectedTab === "ALL" ? "active" : ""}
                      onClick={() => handleChangeTab("ALL")}
                    >
                      Todas
                    </StatusMenuItem>

                    <StatusMenuItem
                      className={selectedTab === "EXCEEDED" ? "active" : ""}
                      onClick={() => handleChangeTab("EXCEEDED")}
                    >
                      Com limite ultrapassado
                    </StatusMenuItem>
                  </StatusMenu>
                </GridCol>
                <GridCol xs={12} sm={4} lg={6}>
                  <SearchInputStyle style={{ width: "100%" }}>
                    <InputBase
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Pesquise por empresa"
                    />
                    <SearchButton>
                      <SearchIcon
                        style={{
                          width: "12px",
                          height: "12px",
                          fill: "#afafaf",
                        }}
                      />
                    </SearchButton>
                  </SearchInputStyle>
                </GridCol>
                <GridCol xs={12} sm={2} lg={2}>
                  <Button size="small" style={{ width: "100%" }} onClick={handleRegisterCompany}>Cadastrar empresa</Button>
                </GridCol>
              </GridRow>
            </GridContainer>
          </CompaniesContainer>
        </GridContainer>
        <GridContainer>
          {loading ? (
            <div style={{ position: "relative", marginTop: "50px" }}>
              <LoadingComponent />
            </div>
          ) : (
            <CompaniesTable data={allCompanies} />
          )}

          <TablePagination
            totalRows={totalPages}
            onPageChange={handlePageChange}
            onRowsPerPageChange={10}
            page={page}
            rowsPerPage={10}
          />
        </GridContainer>
      </Layout>
    </>
  );
};

export default CompaniesList;
