import { links } from "app/config/links";
import { LevelListProps } from "interfaces/Level";
import api from "services/api";

export const getLevels = async (
  page: number,
  perPage: number
) => {
  const response = await api.get(
    links.api.core + `/engineering/master/levels?page=${page}&perPage=${perPage}`,
  );

  return response.data as LevelListProps;
};

export interface LevelFormData {
  name: string;
  bellow_level_id: string | null;
  above_level_id: string | null;
  pair_level_id: string | null;
}

export const saveLevel = async (data: LevelFormData) => {
  const response = await api.post(
    links.api.core + `/engineering/master/levels`,
    data,
  );

  return response.data;
};

export const deleteLevel = async (id: string) => {
  const response = await api.delete(
    links.api.core + `/engineering/master/levels/${id}`,
  );
  return response;
};

export const updateLevel = async (
  id: string,
  data: LevelFormData,
) => {
  const response = await api.put(
    links.api.core + `/engineering/master/levels/${id}`,
    data,
  );

  return response.data;
};
