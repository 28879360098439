import styled from "styled-components";

export const SingleResult = styled.div`
  h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .view-container {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-bottom: 16px;
  }

  strong {
    font-weight: 600;
  }
`;

export const CompaniesContainer = styled.div`
width: 100%;
position: relative;
margin-bottom: 1rem;

`;
export const Title = styled.h1`
display: flex;
align-items: center;
gap: 1rem;

`;
export const TableCellCompanies = styled.td`
padding: 0 16px;
text-align: center;
div.collaborator-cell {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
}

`;
export const TableHeaderCellCompanies = styled.th`
padding: 8px 16px;
text-align: center;
  font-weight: 600;
`;

export const StatusMenu = styled.ul`
  list-style-type: none;
`;

export const StatusMenuItem = styled.li`
  display: inline;
  margin-right: 16px;

  &.active {
    color: ${({ theme }) => theme.colors.primary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
  }
`;


export const LineTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 10px;
    font-size: 12px;
    font-weight: 600;
  }

  div.line {
    flex: 1;
    height: 1.5px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
