import React from "react";
import styled from "styled-components";

export const ChipContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background-color: #e0e0e0;
  border-radius: 16px;
`;

export const ChipLabel = styled.span`
  margin-right: 4px;
  font-size: 14px;
  color: #333;
`;

const ChipDeleteIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  cursor: pointer;
  color: #777;
`;

interface ChipDeletableProps {
  label: string;
  onDelete: () => void;
}

const ChipDeletable: React.FC<ChipDeletableProps> = ({ label, onDelete }) => {
  return (
    <ChipContainer>
      <ChipLabel>{label}</ChipLabel>
      <ChipDeleteIcon onClick={onDelete}>x</ChipDeleteIcon>
    </ChipContainer>
  );
};

export default ChipDeletable;
