export default () => {
  return (
    <svg
      width="32"
      height="23"
      viewBox="0 0 32 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.32846L16.5707 0.038208L32 6.32846L26.7583 8.2383V22.9618H5.17295V8.2383L0 6.32846Z"
        fill="#008AC1"
      />
      <path
        d="M5.1572 8.22821V10.3813L15.9366 14.3918L26.7583 10.3813V8.22821L15.9366 12.1966L5.1572 8.22821Z"
        fill="#00587B"
      />
      <path
        d="M8.53899 21.1465H7.3131H7.14401V14.2652H8.53899V21.1465Z"
        fill="#005171"
      />
    </svg>
  );
};
