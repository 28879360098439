import styled from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 4px;
  justify-content: end;
`;

export const PaginationText = styled.p`
  margin: 0;
  font-size: 14px;
`;

export const PaginationButton = styled.button`
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 10px;
  height: 33px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => (props.disabled ? "#ccc" : "#aaa")};
  }
`;

export const PaginationSelect = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 14px;
  cursor: pointer;
`;

export const PaginationButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
