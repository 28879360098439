import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "components/Tables/styles";
import { WhiteContainer } from "components/WhiteContainer";
import { EmailLogRegisters } from "interfaces/Master";
import { GridContainer } from "layout/components/page-styles";
import React, { useEffect, useState } from "react";
import { getEmailsLog } from "requests/master";

interface EmailsLogProps {
  email: string;
}

const EmailStatus = {
  Delivery: "Entregue",
  Bounce: "Rejeitado",
};

const getEmailStatus = (status) => {
  return EmailStatus[status] ?? status;
};

const EmailsLog: React.FC<EmailsLogProps> = ({ email }) => {
  const [emailsData, setEmailsData] = useState<EmailLogRegisters[]>([]);

  useEffect(() => {
    getEmailsLog(email).then((response) => setEmailsData(response));
  }, []);

  return (
    <GridContainer>
      <WhiteContainer>
        <p style={{ marginBottom: "8px" }}>
          Retornando os últimos 50 registros
        </p>
        <Table>
          <TableHead>
            <TableHeaderCell>Assunto:</TableHeaderCell>
            <TableHeaderCell>Status:</TableHeaderCell>
          </TableHead>
          <TableBody>
            {emailsData.map((email) => (
              <TableRow key={email.message_id}>
                <TableCell>{email.subject}</TableCell>
                <TableCell>{getEmailStatus(email.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </WhiteContainer>
    </GridContainer>
  );
};

export default EmailsLog;
