import { links } from "app/config/links";
import { CertificationItemProps } from "interfaces/Certification";
import api from "services/api";

export const getCertifications = async (
  page: number,
  perPage: number,
  onSelect?: boolean
) => {
  const response = await api.get(
    links.api.core + `/engineering/master/specialization-courses?page=${page}&perPage=${perPage}&onSelect=${onSelect ? onSelect : false}`,
  );

  return response.data as any;
};

export interface CertificationData {
  name: string;
  description: string;
  expiration: string | null;
  id: string;

}

export const saveCertification = async (data: CertificationData) => {
  const response = await api.post(links.api.core + "/engineering/master/specialization-courses", data);

  return response.data as CertificationItemProps;
};

export const updateCertification= async (
  id: string,
  data: CertificationData,
) => {
  const response = await api.put(
    links.api.core + `/engineering/master/specialization-courses/${id}`,
    data,
  );

  return response.data as CertificationItemProps;
};

export const deleteCertification = async (id: string) => {
  const response = await api.delete(
    links.api.core + `/engineering/master/specialization-courses/${id}`
  );
  return response;
};

export const showCertification = async (id: string) => {
  const response = await api.get(`/engineering/master/specialization-courses/${id}`);

  return response.data as CertificationItemProps;
};
