import React, { useEffect } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import { useInterceptor } from "hooks/useInterceptor";
import { useAuth } from "app/hooks/useAuth";
import SearchUser from "pages/Dashboard/SearchUser";
import RegisterCompany from "pages/RegisterCompany";
import { links } from "app/config/links";
import CompaniesList from "pages/Dashboard/CompaniesList";
import Sectors from "pages/Records/Sectors";
import Levels from "pages/Records/Levels";
import Jobs from "pages/Records/Jobs";
import { JobProvider } from "pages/Records/Jobs/context";
import FormJobEdit from "pages/Records/Jobs/JobFormPage/FormPageEdit";
import UserView from "pages/UserView";

const CreateRoutes: React.FC<unknown> = () => {
  const { signOut, refreshToken, user } = useAuth();
  useInterceptor(signOut, refreshToken);

  useEffect(() => {
    if (!user.config.master) {
      window.location.href = links.web.core;
    }
  }, []);

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route index path="/dashboard" element={<Dashboard />} />
        <Route index path="/users/search" element={<SearchUser />} />
        <Route index path="/users/view" element={<UserView />} />
        <Route index path="/records/sectors" element={<Sectors />} />
        <Route
          index
          path="/records/jobs"
          element={
            <JobProvider>
              <Jobs />
            </JobProvider>
          }
        />
        <Route
          path="/records/jobs/:id"
          element={
            <JobProvider>
              <FormJobEdit />
            </JobProvider>
          }
        />
        <Route index path="/records/levels" element={<Levels />} />
        <Route
          index
          path="/companies/list"
          element={<CompaniesList />}
        />
        <Route
          index
          path="/companies/register"
          element={<RegisterCompany />}
        />
      </Routes>
    </HashRouter>
  );
};

export default CreateRoutes;
