import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import DashboardIcon from "../icons/dashboard.svg";
import { MenuContainer, MenuItem, SubMenuItem } from "./styles";
import RecordsIcon from "../icons/records.svg";
import SubMenuIcon from "../icons/arrow-menu.svg";
import CompaniesListIcon from "../icons/list-companies.svg";
import CollaboratorsIcon from "../icons/collaborators.svg";

interface MenuProps {
  isMenuCollapsed: boolean;
  setMenuCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const Menu: React.FC<MenuProps> = ({ isMenuCollapsed, setMenuCollapsed }) => {
  const { pathname } = useLocation();

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(
    pathname.includes("/records")
  );

  const handleSubMenuToggle = (e: any) => {
    e.preventDefault();
    setIsSubMenuOpen((prevIsSubMenuOpen) => !prevIsSubMenuOpen);
    setMenuCollapsed(false);
  };

  const renderMenuItemContent = (icon: string, text: string) => {
    return (
      <>
        <img src={icon} alt={`${text} icon`} />
        {!isMenuCollapsed && text}
      </>
    );
  };

  return (
    <MenuContainer>
      <MenuItem className="dashboard-menu">
        <NavLink
          to="/dashboard"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          {renderMenuItemContent(DashboardIcon, "Dashboard")}
        </NavLink>
      </MenuItem>

      <MenuItem className="dashboard-menu">
        <NavLink
          to="/companies/list"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          {renderMenuItemContent(CompaniesListIcon, "Empresas")}
        </NavLink>
      </MenuItem>

      <MenuItem className="dashboard-menu">
        <NavLink
          to="/users/search"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          {renderMenuItemContent(CollaboratorsIcon, "Contas")}
        </NavLink>
      </MenuItem>

      <MenuItem isSubMenuOpen={isSubMenuOpen} className="records-menu">
        <NavLink
          onClick={handleSubMenuToggle}
          to="/records"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          {renderMenuItemContent(RecordsIcon, "Cadastros master")}
          {!isMenuCollapsed && (
            <span>
              <img src={SubMenuIcon} alt="Submenu_Icon" />
            </span>
          )}
        </NavLink>

        {!isMenuCollapsed && isSubMenuOpen && (
          <ul>
            <SubMenuItem>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/records/sectors"
              >
                Setor [master]
              </NavLink>
            </SubMenuItem>
            <SubMenuItem>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/records/jobs"
              >
                Cargo [master]
              </NavLink>
            </SubMenuItem>
            <SubMenuItem>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/records/levels"
              >
                Nível [master]
              </NavLink>
            </SubMenuItem>
          </ul>
        )}
      </MenuItem>
    </MenuContainer>
  );
};

export default Menu;
