import React, { useState } from "react";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import Warning from "components/Icons/Alerts/Warning";
import { Button, OutlinedButton } from "components/Buttons/styles";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import Input from "components/Forms/Input";
import { InputBase } from "components/Forms/Input/Input";
import { FieldWrapped } from "components/Forms/FieldWrapped";
import { Label } from "components/Forms/Label";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingComponent from "components/LoadingComponent";
import { addToast } from "app/components/Toast";
import { changeWhiteLabelLogo } from "requests/master";

interface WhiteLabelModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  companyId: string;
}

const schema = yup.object({
  partner_name: yup.string().required("Campo obrigatório"),
});

interface ChangeWhiteLabelProps {
  partner_name: string;
}

const WhiteLabelModal: React.FC<WhiteLabelModalProps> = ({
  open,
  setOpen,
  companyId,
}) => {
  const [fileLogo, setFileLogo] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setOpen(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeWhiteLabelProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ChangeWhiteLabelProps) => {
    if (!fileLogo) {
      addToast(`Você não adicionou nenhum logo.`, { appearance: "warning" });
      return;
    }

    setLoading(true);

    const newData = {
      partner_name: data.partner_name,
      logo: fileLogo[0],
      company_id: companyId,
    };

    changeWhiteLabelLogo(newData)
      .then(() => {
        setLoading(false);
        handleClose();
        addToast(`O logo foi alterado com sucesso.`, { appearance: "success" });
      })
      .catch((err) => {
        addToast(
          `Ocorreu um erro inesperado. Atualize a página e tente novamente.`,
          { appearance: "error" }
        );
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      icon={<Warning />}
      title="Editar WhiteLabel"
    >
      {loading ? <LoadingComponent /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <GridContainer>
            <GridRow>
              <GridCol xs={12} sm={12} lg={12}>
                <Input
                  required={true}
                  label="Remetente"
                  limit={20}
                  inputProps={{ ...register("partner_name") }}
                  error={errors.partner_name}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={12} sm={12} lg={12}>
                <FieldWrapped>
                  <Label required={true}>Logo whitelabel</Label>
                  <InputBase
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => setFileLogo(e.target.files)}
                  />
                </FieldWrapped>
              </GridCol>
            </GridRow>
          </GridContainer>
        </ModalContent>
        <ModalActions>
          <OutlinedButton size="small" onClick={handleClose} type="button">
            Cancelar
          </OutlinedButton>
          <Button size="small" type="submit">
            Salvar alterações
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

export default WhiteLabelModal;
