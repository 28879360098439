import React from "react";

const CloseIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0001 0.50003C14.4479 -0.0522763 13.5524 -0.0523291 13.0001 0.499912L7.99929 5.5L2.99982 0.500088C2.44755 -0.0522306 1.55209 -0.0522306 0.999823 0.500088C0.447622 1.05234 0.447622 1.94766 0.999823 2.49991L5.99947 7.5L0.999823 12.5001C0.447622 13.0523 0.447622 13.9477 0.999823 14.4999C1.55209 15.0522 2.44755 15.0522 2.99982 14.4999L7.99929 9.5L13.0001 14.5001C13.5524 15.0523 14.4479 15.0523 15.0001 14.5C15.5524 13.9477 15.5524 13.0523 15.0001 12.5L10.0005 7.5L15.0001 2.49997C15.5524 1.94769 15.5524 1.05231 15.0001 0.50003Z"
        fill="#6C757D"
      />
    </svg>
  );
};

export default CloseIcon;
