export default () => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0L0 19H22M11 4L18.53 17H3.47M10 8V12H12V8M10 14V16H12V14"
        fill="#F18932"
      />
    </svg>
  );
};
