import React from "react";
import useSWR from "swr";
import { getS3Object } from "services/aws";

interface RenderImageProps {
  src: string;
  width: string;
  height: string;
}

const RenderImage: React.FC<RenderImageProps> = ({ src, width, height }) => {
  const fetcher = async () => {
    try {
      const imageUrl = await getS3Object(src);
      return imageUrl;
    } catch (err) {
      return null;
    }
  };

  const { data: url } = useSWR(src, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  if (src && url) {
    return (
      <img
        src={url}
        width={width}
        height={height}
        style={{ width: `${width}px`, height: `${height}px` }}
      />
    );
  }

  return null;
};

export default RenderImage;
