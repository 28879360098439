import React, { useImperativeHandle, useState } from "react";
import {
  Box,
  Divider,
  Icon,
  IconButton,
  Link as MUILink,
  Menu,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHeaderProvider } from "app/contexts/HeaderContext";
import { incicleModules } from "app/components/InHeader/data/modules";

export type ModulesMenuRef = {
  openDropdown: (ev: any) => void;
  closeDropdown: (ev: any) => void;
};

type Props = {};

const breakpointValue = 700;

const ModulesMenu: React.ForwardRefRenderFunction<ModulesMenuRef, Props> = (
  props,
  ref
) => {
  const { user } = useHeaderProvider();
  const { breakpoints } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function openDropdown(ev: any) {
    setAnchorEl(ev.currentTarget);
  }

  function closeDropdown() {
    setAnchorEl(null);
  }

  useImperativeHandle(ref, () => {
    return {
      openDropdown,
      closeDropdown,
    };
  });

  return (
    <Menu
      open={Boolean(anchorEl)}
      onClose={closeDropdown}
      anchorEl={anchorEl}
      PaperProps={{
        sx: { maxWidth: 680 },
      }}
    >
      <Stack
        position="relative"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        padding="0 16px"
        gap={2}
      >
        <Box
          sx={{
            width: "100%",
            marginTop: "12px",
            marginBottom: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              color: "#008AC1",
              fontSize: "26px",
              fontWeight: "bold",
            }}
          >
            Módulos
          </Typography>
        </Box>
        <IconButton sx={{ marginBottom: "8px" }} onClick={closeDropdown}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          padding: "8px 8px 0",
          [breakpoints.down(breakpointValue)]: {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        {incicleModules
          .filter((item) => item.accountTypes.includes(user.type))
          .map((moduleItem) => (
            <Tooltip
              key={moduleItem.slug}
              title={!moduleItem.url ? "Módulo disponível em breve" : ""}
              placement="top"
              arrow
              PopperProps={{
                sx: {
                  "*": {
                    fontSize: "11px !important",
                  },
                  bottom: "-20px !important",
                },
              }}
            >
              <IconButton
                key={moduleItem.title}
                sx={{
                  width: "49%",
                  height: "max-content",
                  borderRadius: "0 !important",
                  fontSize: "18px !important",
                  [breakpoints.down(breakpointValue)]: {
                    width: "100%",
                  },
                }}
              >
                <MUILink
                  href={moduleItem.url || ""}
                  underline="none"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "5px",
                    pointerEvents: !moduleItem.url ? "none" : "",
                  }}
                >
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minWidth: 60, minHeight: 60 }}
                  >
                    <Icon
                      component={Stack}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      padding={1}
                      width={`${moduleItem.iconSize}px !important`}
                      height={`${moduleItem.iconSize}px !important`}
                    >
                      <img
                        src={moduleItem.icon}
                        alt={moduleItem.title}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Icon>
                  </Stack>
                  <Box
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      sx={{
                        color: !moduleItem.url ? "#848484" : "#008AC1",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {moduleItem.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: !moduleItem.url ? "#848484" : "#535353",
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    >
                      {user?.type && moduleItem.description
                        ? moduleItem?.description[user?.type]
                        : ""}
                    </Typography>
                  </Box>
                </MUILink>
              </IconButton>
            </Tooltip>
          ))}
      </Box>
    </Menu>
  );
};

export default React.forwardRef(ModulesMenu);
