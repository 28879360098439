import { JobItemProps } from "interfaces/Job";
import React, { createContext, useContext, useState } from "react";

interface JobContext {
  jobList: JobItemProps[];
  setJobList: React.Dispatch<React.SetStateAction<JobItemProps[]>>;
  handleAddJob: (job: JobItemProps) => void;
  newJobAdded: boolean;
  setNewJobAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

const JobContext = createContext<JobContext>({} as JobContext);

const JobProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [jobList, setJobList] = useState<JobItemProps[]>([]);
  const [newJobAdded, setNewJobAdded] = useState(false);

  const handleAddJob = (job: JobItemProps) => {
    const newJob = {
      ...job,
      isNew: true,
    };
    setJobList((old) => [newJob, ...old]);
    setNewJobAdded(true);
  };

  const context = {
    jobList,
    setJobList,
    handleAddJob,
    newJobAdded,
    setNewJobAdded,
  };

  return <JobContext.Provider value={context}>{children}</JobContext.Provider>;
};

const useJobsContext = () => useContext(JobContext);

export { JobProvider, useJobsContext };
