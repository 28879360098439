import React from "react";
import CreateRoutes from "./routes";
import { ThemeProvider } from "styled-components";
import { theme } from "./layout/theme";
import { AuthProvider } from "app/contexts/AuthContext";
import ProfileProvider from "context/ProfileContext";
import { ToastProvider } from "react-toast-notifications";
import LogRocket from "logrocket";
import { PermissionsProvider } from "context/Permissions";
// LogRocket.init("gblrf9/incicle");

function App() {
  return (
    <AuthProvider>
      <PermissionsProvider>
        <ProfileProvider>
          <ToastProvider>
            <ThemeProvider theme={theme}>
              <CreateRoutes />
            </ThemeProvider>
          </ToastProvider>
        </ProfileProvider>
      </PermissionsProvider>
    </AuthProvider>
  );
}

export default App;
