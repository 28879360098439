import React from "react";
import { AvatarImage, AvatarInitials, AvatarWrapper } from "./styles";
import useSWR from "swr";
import { getS3Object } from "services/aws";

type AvatarProps = {
  src: string;
  name?: string;
  size?: string;
  fontSize?: string;
};

const getInitials = (name: string) => {
  const [firstName, lastName] = name.split(" ");
  return `${firstName[0]}${lastName ? lastName[0] : ""}`;
};

type AlphabetColorMap = { [key: string]: string };

const alphabetColors: AlphabetColorMap = {
  A: "#0EAD4E",
  B: "#AD640E",
  C: "#0E87AD",
  D: "#33B888",
  E: "#97AD0E",
  F: "#AD470E",
  G: "#AD0E94",
  H: "#AD0E0E",
  I: "#57AD0E",
  J: "#0E56AD",
  K: "#4F0EAD",
  L: "#AD0E56",
  M: "#AD0E1B",
  N: "#711CAD",
  O: "#0EAD86",
  P: "#AD220E",
  Q: "#7A0EAD",
  R: "#4EAD0E",
  S: "#5F0EAD",
  T: "#AD7B0E",
  U: "#0EAD26",
  V: "#AD0E39",
  W: "#2DAD0E",
  X: "#AD0E72",
  Y: "#0EADBC",
  Z: "#AD0E2E",
};

function getLetterColor(letter: string): string {
  const capitalizedLetter = letter.toUpperCase();

  return alphabetColors[capitalizedLetter] || "#000";
}

const Avatar: React.FC<AvatarProps> = ({ src, name, size, fontSize }) => {
  const fetcher = async () => {
    try {
      const imageUrl = await getS3Object(src);
      return imageUrl;
    } catch (err) {
      return null;
    }
  };

  const { data: url } = useSWR(src, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  if (src && url) {
    return (
      <AvatarWrapper size={size}>
        <AvatarImage src={url} alt="Avatar" />
      </AvatarWrapper>
    );
  }

  if (name) {
    const initials = getInitials(name);
    const backgroundColor = getLetterColor(initials.charAt(0));

    return (
      <AvatarWrapper size={size} style={{ backgroundColor }}>
        <AvatarInitials fontSize={fontSize}>{initials}</AvatarInitials>
      </AvatarWrapper>
    );
  }

  return null;
};

export default Avatar;
