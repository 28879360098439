import styled from "styled-components";

interface BehavioralItemStyleProps {
  color: string;
}

export const BehavioralItemStyle = styled.div<BehavioralItemStyleProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ color }) => color};
  padding: 5px 8px;
  font-size: 12px;

  div.labels {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    font-weight: 600;
  }

  button {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
  }
`;
