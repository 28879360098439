export const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  colors: {
    primary: "#0085b0",
    primaryDark: " #005b7c",
    danger: "#D40000",
    dangerDark: "#8C0000",
    text: "rgba(33, 37, 41, 1)",
    success: "#00A424",
    warning: "rgb(255, 153, 0)",
  },
  spacing: (factor: any) => `${0.25 * factor}rem`,
};
