import { links } from "app/config/links";
import {
  CreateJobProps,
  JobItemList,
  ShowJobItemProps,
  StudyAreaListProps,
} from "interfaces/Job";
import api from "services/api";

export const getJobs = async (
  page: number,
  perPage: number,
  onSelect?: boolean
) => {
  const response = await api.get(
    links.api.core + `/engineering/master/jobs?page=${page}&perPage=${perPage}`,
  );

  return response.data as JobItemList;
};

export const deleteJob = async (id: string) => {
  const response = await api.delete(
    links.api.core + `/engineering/master/jobs/${id}`
  );
  return response;
};

export const getStudyAreas = async (companyId: string, search: string) => {
  const response = await api.get(
    links.api.core + `/study-areas?search=${search}&page=1&perPage=99999`,
  );
  return response.data as StudyAreaListProps;
};

export const saveStudyArea = async (value: string) => {
  const response = await api.post(
    links.api.core + `/study-areas`,
    {
      name: value,
    },
  );

  return response.data;
};

export const saveJob = async (data: CreateJobProps) => {
  const response = await api.post(links.api.core + "/engineering/master/jobs", data);

  return response.data;
};

export const showJob = async (id: string) => {
  const response = await api.get(links.api.core + `/engineering/master/jobs/${id}`);

  return response.data as ShowJobItemProps;
};

export const updateJob = async (
  id: string,
  data: CreateJobProps,
) => {
  const response = await api.put(
    links.api.core + `/engineering/master/jobs/${id}`,
    data
  );

  return response.data as ShowJobItemProps;
};

export const getCompetencesList = async () => {
  const response = await api.get(
    links.api.evaluation + "/evaluation-competence/competences?perPage=9999",
  );

  return response.data.data;
};
