import React, { useState } from "react";
import { TableCell, TableRow } from "components/Tables/styles";
import Avatar from "components/Avatar";
import { CompanyData } from "interfaces/Companies";
import UpdateCollaboratorsModal from "../UpdateCollaboratorsModal";
import { TableCellCompanies } from "../styles";
import CompanyActionTrashCell from "../CompanyActionTrashCell";
import { searchUser } from "requests/master";
import { useNavigate } from "react-router-dom";

interface CompaniesItemProps {
  company: CompanyData;
}

const CompaniesItem: React.FC<CompaniesItemProps> = ({ company }) => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [companyCopy, setCompanyCopy] = useState<CompanyData>(company);
  const navigate = useNavigate();

  const handleOpen = () => setOpenUpdateModal(true);

  const phone =
    companyCopy.phones && companyCopy.phones.length > 0
      ? companyCopy.phones[0].phone_number
      : "";

  const handleViewAccount = () => {
    const { email } = company.user;

    searchUser({ type: "email", value: email }).then((response) => {
      navigate("/users/view", { state: response });
    });
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              cursor: "pointer",
            }}
            onClick={handleViewAccount}
          >
            <div>
              <Avatar name={companyCopy.name} src={company?.avatar} />{" "}
            </div>
            {companyCopy.name}
          </div>
        </TableCell>

        <TableCellCompanies>{companyCopy.cnpj}</TableCellCompanies>
        <TableCellCompanies>{phone}</TableCellCompanies>
        <TableCellCompanies>{companyCopy.user.email}</TableCellCompanies>
        <TableCellCompanies>
          {companyCopy.legal_representative}
        </TableCellCompanies>

        <TableCellCompanies>
          <CompanyActionTrashCell company={companyCopy} onClick={handleOpen} />
        </TableCellCompanies>
        <TableCellCompanies>
          {companyCopy.commercial_activity}
        </TableCellCompanies>
        <TableCellCompanies>
          {!!companyCopy.white_label ? "Sim" : "Não"}
        </TableCellCompanies>
      </TableRow>

      {openUpdateModal ? (
        <UpdateCollaboratorsModal
          open={openUpdateModal}
          title="Atualizar quantidade contratada"
          company={companyCopy}
          setOpen={setOpenUpdateModal}
          setCompany={setCompanyCopy}
        />
      ) : null}
    </>
  );
};

export default CompaniesItem;
