import styled from "styled-components";

export const DashboardPage = styled.div`
  width: 100%;
`;

export const GraphLabels = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 16px;
`;

interface LabelGraphProps {
  color: string;
}

export const LabelGraph = styled.span<LabelGraphProps>`
  font-family: "Myriad Pro", sans-serif;
  font-size: 16px;
  color: #000;

  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    margin-right: 5px;
  }
`;
