import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { DashboardPage, GraphLabels, LabelGraph } from "./styles";
import {
  ContainerTitle,
  GridCol,
  GridContainer,
  GridRow,
} from "../../layout/components/page-styles";
import CollaboratorsGraph from "./CollaboratorsGraph";
import { WhiteContainer } from "components/WhiteContainer";
import { IndicatorsProps, getIndicators } from "requests/master";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "components/Tables/styles";

const Dashboard: React.FC<unknown> = () => {
  const [indicatorData, setIndicatorData] = useState<IndicatorsProps>();

  useEffect(() => {
    getIndicators().then((response) => setIndicatorData(response));
  }, []);

  return (
    <Layout>
      <DashboardPage>
        <GridContainer style={{ marginTop: "10px" }}>
          <WhiteContainer>
            <GridContainer>
              <GridRow>
                <GridCol xs={12} sm={4} lg={4}>
                  <ContainerTitle>
                    <div style={{ fontWeight: 600 }}>Adesão ao sistema</div>
                    <GraphLabels>
                      <LabelGraph color="#008AC1">Empresas</LabelGraph>
                      <LabelGraph color="#FF842B">Pessoas</LabelGraph>
                    </GraphLabels>
                  </ContainerTitle>

                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {indicatorData ? (
                      <CollaboratorsGraph data={indicatorData} />
                    ) : null}
                  </div>
                </GridCol>
              </GridRow>
            </GridContainer>
          </WhiteContainer>
        </GridContainer>
      </DashboardPage>
    </Layout>
  );
};

export default Dashboard;
