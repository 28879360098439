import React from "react";
import styled, { keyframes } from "styled-components";

interface CircularProgressProps {
  size: number;
  thickness: number;
  color?: string;
}

const CircularProgress: React.FC<CircularProgressProps> = ({
  size,
  thickness,
  color,
}) => {
  const circumference = 2 * Math.PI * (size / 2 - thickness / 2);
  const offset = circumference - (circumference * 70) / 100;

  return (
    <StyledCircularProgress
      size={size}
      thickness={thickness}
      color={color}
      circumference={circumference}
      offset={offset}
    >
      <svg viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - thickness / 2}
          strokeWidth={thickness}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
    </StyledCircularProgress>
  );
};

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

interface StyledCircularProgressProps {
  size: number;
  thickness: number;
  color?: string;
  circumference: number;
  offset: number;
}

const StyledCircularProgress = styled.div<StyledCircularProgressProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  svg {
    animation: ${rotate} 1.4s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
  }
  circle {
    fill: transparent;
    stroke: ${({ color, theme }) => (color ? color : theme.colors.primary)};
    stroke-linecap: round;
    stroke-width: ${(props) => props.thickness}px;
    stroke-dasharray: ${(props) => props.circumference};
    stroke-dashoffset: ${(props) => props.offset};
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: center center;
  }
};`;

export default CircularProgress;
