import styled from "styled-components";

export const NavMenu = styled.ul`
  list-style-type: none;
`;

export const NavMenuItem = styled.li`
  display: inline;
  margin-right: 16px;

  &.active {
    color: ${({ theme }) => theme.colors.primary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
  }
`;
