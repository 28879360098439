import React, { useEffect, useState } from "react";
import { Button, OutlinedButton } from "components/Buttons/styles";
import Input from "components/Forms/Input";
import Textarea from "components/Forms/Textarea";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import ModalLoadingOverlay from "components/Modal/ModalLoadingOverlay";
import AddSectorIcon from "components/Modal/icons/AddSector";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getCollaborators } from "requests/collaborators";
import { Label } from "components/Forms/Label";
import { FieldWrapped } from "components/Forms/FieldWrapped";
import SelectAutocomplete from "components/Forms/SelectAutocomplete";
import {
  ResponseSector,
  saveSector,
  showSector,
  updateSector,
} from "requests/sectors";
import { SectorItemProps } from "interfaces/Sector";

interface SectorFormProps {
  setSectorList: React.Dispatch<React.SetStateAction<SectorItemProps[]>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: "add" | "edit";
  sectorId?: string;
}

interface FormData {
  name: string;
  description: string;
  cost_center: string | null;
  responsible_id: string | null;
}

const maxCharacters = {
  name: 100,
  description: 250,
};

const schema = yup.object({
  name: yup
    .string()
    .required("Campo obrigatório")
    .max(maxCharacters.name, "Máximo de caracteres: 100"),
  responsible_id: yup.string().nullable(),
  cost_center: yup.string().nullable(),
  description: yup.string().required("Campo obrigatório"),
});

const convertForAutocomplete = (data) =>
  data.map((item) => ({
    value: item.id,
    label: item.person.name,
  }));

const SectorForm: React.FC<SectorFormProps> = ({
  type,
  open,
  setOpen,
  setSectorList,
  sectorId,
}) => {
  const [loading, setLoading] = useState(type === "add" ? false : true);
  const [dataEdit, setDataEdit] = useState<ResponseSector | null>(null);

  const methods = useForm<FormData>({
    //resolver: yupResolver(schema),
    defaultValues: {
      name: dataEdit?.name,
    },
  });

  const {
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (type === "edit" && sectorId) {
      showSector(sectorId).then((response) => {
        setDataEdit(response);
        methods.setValue("name", response.name);
        methods.setValue("description", response.description);
        methods.setValue("cost_center", response.cost_center);
        methods.setValue("responsible_id", response.responsible_id);
        setLoading(false);
      });
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: FormData) => {
    setLoading(true);

    const newData = {
      ...data
    };

    saveSector(newData).then((response) => {
      const newSector = {
        ...response,
        isNew: true,
      };
      setSectorList((old) => [newSector, ...old]);
      setLoading(false);
      setOpen(false);
    });
  };

  const onSubmitEdit = (data: FormData) => {
    if (!sectorId) return;
    setLoading(true);
    updateSector(sectorId, data).then((updatedSector) => {
      setSectorList((old) =>
        old.map((sector) =>
          sector.id === updatedSector.id ? updatedSector : sector
        )
      );
      setLoading(false);
      setOpen(false);
    });
  };

  return (
    <Modal
      open={open}
      title={type === "add" ? "Cadastrar setor" : "Editar setor"}
      onClose={handleClose}
      icon={<AddSectorIcon />}
    >
      {loading ? <ModalLoadingOverlay /> : null}

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(
            type === "add" ? onSubmit : onSubmitEdit
          )}
        >
          <ModalContent>
            <GridContainer>
              <GridRow>
                <GridCol xs={12} sm={12} lg={12}>
                  <Input
                    required={true}
                    label="Nome do setor"
                    limit={maxCharacters.name}
                    inputProps={{
                      ...methods.register("name"),
                    }}
                    error={methods.formState.errors.name}
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol xs={12} sm={12} lg={12}>
                  <Textarea
                    limit={250}
                    required={true}
                    label="Descrição do setor"
                    inputProps={{
                      ...methods.register("description"),
                    }}
                    error={methods.formState.errors.description}
                  />
                </GridCol>
              </GridRow>
            </GridContainer>
          </ModalContent>
          <ModalActions>
            <OutlinedButton size="small" onClick={handleClose}>
              Cancelar
            </OutlinedButton>
            <Button size="small" type="submit">
              {type === "add" ? "Salvar setor" : "Atualizar setor"}
            </Button>
          </ModalActions>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SectorForm;
