export default () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7363 19.8846V24.4999H1V0.763672H5.61539V5.37906H10.4945V10.1263H15.3736V15.0054H20.1209V19.8846H24.7363Z"
        fill="#003B5D"
        stroke="#003B5D"
        stroke-width="0.505495"
      />
      <path
        d="M24.9997 11.9725L23.5492 13.4231L14.3184 4.32418L12.0767 6.56593V0.5H18.0107L15.769 2.74176L24.9997 11.9725Z"
        fill="#0085B0"
        stroke="#0085B0"
        stroke-width="0.505495"
      />
    </svg>
  );
};
