import { theme } from "layout/theme";
import styled from "styled-components";

interface HelperTextProps {
  color?: "danger" | "primary" | "text";
}

const getHelperTextColor = (color: "danger" | "primary" | "text") => {
  const selectColor = {
    danger: theme.colors.danger,
    text: theme.colors.text,
    primary: theme.colors.primary,
  };

  return selectColor[color];
};

export const HelperText = styled.small<HelperTextProps>`
  font-size: 10px;
  color: ${({ theme, color }) =>
    color !== undefined ? getHelperTextColor(color) : "text"};
`;
