import { addToast } from "app/components/Toast";
import { OutlinedButton } from "components/Buttons/styles";
import { InputBase } from "components/Forms/Input/Input";
import LoadingComponent from "components/LoadingComponent";
import { WhiteContainer } from "components/WhiteContainer";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import AutentiqueModal from "pages/Dashboard/SearchUser/AutentiqueModal";
import React, { useEffect, useState } from "react";
import api from "services/api";

interface AuthentiqueProps {
  companyId: string;
}

const getAutentiqueApiKey = async (companyId: string) => {
  const response = await api.get(`/companies/${companyId}/signature/`);
  return response.data;
};

const Authentique: React.FC<AuthentiqueProps> = ({ companyId }) => {
  const [authentiqueKey, setAuthentiqueKey] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleEditOpen = () => setModalOpen(true);

  useEffect(() => {
    getAutentiqueApiKey(companyId)
      .then((response) => {
        setAuthentiqueKey(response.apiKey);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        addToast("Erro ao carregar chave do Autentique. Recarregue a páginas", {
          appearance: "error",
        });
      });
  }, [companyId]);

  return (
    <>
      <WhiteContainer>
        {loading ? <LoadingComponent /> : null}

        <GridContainer>
          <GridRow>
            <GridCol
              xs={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <strong
                  style={{
                    fontWeight: 600,
                    color: "#003B5D",
                    fontSize: "18px",
                  }}
                >
                  Authentique API Key
                </strong>
              </div>

              <OutlinedButton
                size="small"
                type="button"
                onClick={handleEditOpen}
              >
                Editar
              </OutlinedButton>
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol
              xs={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <InputBase
                value={authentiqueKey ?? ""}
                style={{ width: "100%" }}
                readOnly
                disabled
              />
            </GridCol>
          </GridRow>
        </GridContainer>
      </WhiteContainer>

      {modalOpen && (
        <AutentiqueModal
          companyId={companyId}
          autentiqueApiKey={authentiqueKey}
          setAutentiqueApiKey={setAuthentiqueKey}
          open={modalOpen}
          setOpen={setModalOpen}
        />
      )}
    </>
  );
};

export default Authentique;
