import { Options } from "../types";

export const options: Options = {
  "pt-BR": {
    months: {
      one: {
        full: "[n] mês atrás",
      },
      more: {
        full: "[n] meses atrás",
      },
    },
    days: {
      one: {
        full: "[n] dia atrás",
        mini: "[n] d",
      },
      more: {
        full: "[n] dias atrás",
        mini: "[n] d",
      },
    },
    hours: {
      one: {
        full: "[n] hora atrás",
        mini: "[n] h",
      },
      more: {
        full: "[n] horas atrás",
        mini: "[n] h",
      },
    },
    minutes: {
      one: {
        full: "[n] minuto atrás",
        mini: "[n] min",
      },
      more: {
        full: "[n] minutos atrás",
        mini: "[n] min",
      },
    },
    seconds: {
      one: {
        full: "[n] segundo atrás",
        mini: "[n] s",
      },
      more: {
        full: "[n] segundos atrás",
        mini: "[n] s",
      },
    },
  },
};
