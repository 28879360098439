import { OutlinedButton } from "components/Buttons/styles";
import { InputBase } from "components/Forms/Input/Input";
import { WhiteContainer } from "components/WhiteContainer";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import LinkRedirectModal from "pages/Dashboard/SearchUser/LinkRedirectModal";
import React, { useState } from "react";

interface StepOneProps {
  data: any;
  numberCollaborators: number;
  companyId: string;
}

const StepOne: React.FC<StepOneProps> = ({ data, numberCollaborators, companyId }) => {
  const [linkStepOne, setLinkStepOne] = useState<string>(data?.url);
  const [modalOpen, setModalOpen] = useState(false);

  const handleEditOpen = () => setModalOpen(true);

  return (
    <>
      <WhiteContainer>
        <GridContainer>
          <GridRow>
            <GridCol
              xs={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <strong
                  style={{
                    fontWeight: 600,
                    color: "#003B5D",
                    fontSize: "18px",
                  }}
                >
                  StepOne
                </strong>
              </div>

              <OutlinedButton
                size="small"
                type="button"
                onClick={handleEditOpen}
              >
                Editar
              </OutlinedButton>
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol
              xs={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <InputBase
                value={linkStepOne}
                style={{ width: "100%" }}
                readOnly
                disabled
              />
            </GridCol>
          </GridRow>
        </GridContainer>
      </WhiteContainer>

      {modalOpen && (
        <LinkRedirectModal
          open={modalOpen}
          companyId={companyId}
          setOpen={setModalOpen}
          url={linkStepOne}
          numberCollaborators={numberCollaborators}
          setLink={setLinkStepOne}
        />
      )}
    </>
  );
};

export default StepOne;
