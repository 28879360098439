import React, { useEffect, useState } from "react";
import { Label } from "../Label";
import { TextareaBase } from "./Textarea";
import { FieldWrapped } from "../FieldWrapped";
import { CharacterCounter } from "../CharacterCounter";
import { HelperText } from "../Helpers/HelperText";
import { FieldError } from "react-hook-form";

interface TextareaProps {
  label?: string;
  required?: boolean;
  limit: number;
  inputProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  error?: FieldError | undefined;
  rows?: number;
}

const Textarea: React.FC<TextareaProps> = ({
  label,
  required,
  limit,
  inputProps,
  error,
  rows,
}) => {
  const [charCount, setCharCount] = useState(
    inputProps && inputProps.defaultValue
      ? inputProps.defaultValue.toString().length
      : 0
  );

  useEffect(() => {
    if (inputProps && inputProps.defaultValue) {
      setCharCount(inputProps.defaultValue.toString().length);
    }
  }, [inputProps?.defaultValue]);

  const handleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setCharCount(event.currentTarget.value.length);
  };
  return (
    <>
      <FieldWrapped>
        {label && (
          <Label>
            {label}
            {required && <span style={{ color: "#D40000" }}>*</span>}
          </Label>
        )}
        <TextareaBase
          maxLength={limit}
          onInput={handleChange}
          rows={rows ? rows : 3}
          hasError={error !== undefined ? Boolean(error) : false}
          {...inputProps}
        />

        {limit && (
          <CharacterCounter>
            {charCount}/{limit}
          </CharacterCounter>
        )}
      </FieldWrapped>
      {error ? <HelperText color="danger">{error.message}</HelperText> : null}
    </>
  );
};

export default Textarea;
