import Avatar from "components/Avatar";
import { ChipContainer, ChipLabel } from "components/ChipDeletable";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "components/Tables/styles";
import { WhiteContainer } from "components/WhiteContainer";
import { GridContainer } from "layout/components/page-styles";
import React, { useEffect, useState } from "react";
import { getCompanyCollaborators } from "requests/master";

interface CollaboratorsInterface {
  companyId: string;
}

const Collaborators: React.FC<CollaboratorsInterface> = ({ companyId }) => {
  const [collaborators, setCollaborators] = useState<any[]>([]);
  const [collaboratorsCount, setCollaboratorsCount] = useState<number | null>(
    null
  );

  useEffect(() => {
    getCompanyCollaborators(companyId).then((response) => {
      setCollaborators(response.data.data);
      setCollaboratorsCount(response.data.meta.total);
    });
  }, [companyId]);

  return (
    <GridContainer>
      <WhiteContainer>
        {collaboratorsCount && (
          <div style={{ marginBottom: "16px" }}>
            <ChipContainer>
              <ChipLabel>
                <span style={{ fontWeight: "600" }}>Total de colaboradores:</span> {collaboratorsCount}
              </ChipLabel>
            </ChipContainer>
          </div>
        )}

        <Table>
          <TableHead>
            <TableHeaderCell>Colaborador</TableHeaderCell>
            <TableHeaderCell>E-mail</TableHeaderCell>
          </TableHead>
          <TableBody>
            {collaborators?.map((collaborator) => (
              <TableRow key={collaborator}>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Avatar
                      name={collaborator?.person?.name}
                      src={collaborator?.person?.avatar}
                    />{" "}
                    {collaborator?.person?.name}
                  </div>
                </TableCell>

                <TableCell>{collaborator?.person?.user?.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </WhiteContainer>
    </GridContainer>
  );
};

export default Collaborators;
