import React from "react";
import { BehavioralItemStyle } from "./style";
import { CompetenceInterface } from "interfaces/Job";

interface BehavioralProfilesItem {
  number: number;
  title: string;
  color: string;
  setCompetencesList: React.Dispatch<
    React.SetStateAction<CompetenceInterface[]>
  >;
  competenceId: string;
}

const BehavioralProfilesItem: React.FC<BehavioralProfilesItem> = ({
  number,
  title,
  color,
  setCompetencesList,
  competenceId,
}) => {
  const handleRemove = () => {
    setCompetencesList((old) =>
      old.filter((item) => item.competence_id !== competenceId)
    );
  };

  return (
    <BehavioralItemStyle color={color}>
      <div className="labels">
        <span>{number}%</span>
        <span>{title}</span>
      </div>
      <div>
        <button type="button" onClick={handleRemove}>
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.70711"
              y1="1.29289"
              x2="8.97983"
              y2="8.56562"
              stroke="#646464"
              stroke-opacity="0.63"
              stroke-width="2"
            />
            <line
              y1="-1"
              x2="10.2852"
              y2="-1"
              transform="matrix(-0.707107 0.707107 0.707107 0.707107 9 2)"
              stroke="#646464"
              stroke-opacity="0.63"
              stroke-width="2"
            />
          </svg>
        </button>
      </div>
    </BehavioralItemStyle>
  );
};

export default BehavioralProfilesItem;
