import {
  Table,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "components/Tables/styles";
import { CompanyData } from "interfaces/Companies";
import React from "react";
import CompaniesItem from "../CompaniesItem";
import { TableHeaderCellCompanies } from "../styles";

interface CompaniesTableProps {
  data: CompanyData[];
}

const CompaniesTable: React.FC<CompaniesTableProps> = ({ data }) => {
  return (
    <Table style={{ overflowX: "auto" }}>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Empresa</TableHeaderCell>
          <TableHeaderCellCompanies>CNPJ</TableHeaderCellCompanies>
          <TableHeaderCellCompanies>Telefone</TableHeaderCellCompanies>
          <TableHeaderCellCompanies>E-mail</TableHeaderCellCompanies>
          <TableHeaderCellCompanies>Representante</TableHeaderCellCompanies>
          <TableHeaderCellCompanies>Colaboradores</TableHeaderCellCompanies>
          <TableHeaderCellCompanies>Ramo</TableHeaderCellCompanies>
          <TableHeaderCellCompanies>White Label</TableHeaderCellCompanies>
        </TableRow>
      </TableHead>

      <TableBody>
        {data.map((company, key) => (
          <CompaniesItem key={key} company={company} />
        ))}
      </TableBody>
    </Table>
  );
};

export default CompaniesTable;
