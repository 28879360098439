import React from "react";
import ReactApexChart from "react-apexcharts";
import { IndicatorsProps } from "requests/master";

interface CollaboratorsGraph {
  data: IndicatorsProps;
}

const CollaboratorsGraph: React.FC<CollaboratorsGraph> = ({ data }) => {
  const chartConfig = {
    series: [data.total_companies, data.total_persons],
    options: {
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      legend: {
        show: false,
      },
      dataLabels: {
        formatter: function (val: any) {
          return val.toFixed(0) + "%";
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
          },
        },
      },
      labels: ["Empresas ativas", "Usuários ativos"],
      colors: ["#008AC1", "#FF842B"],
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={chartConfig.options}
        series={chartConfig.series}
        type="donut"
        width={400}
      />
    </div>
  );
};

export default CollaboratorsGraph;
