import React, { useState } from "react";
import { Button, OutlinedButton } from "components/Buttons/styles";
import { InputBase } from "components/Forms/Input/Input";
import LoadingComponent from "components/LoadingComponent";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import CompaniesIcon from "components/Icons/CompaniesIcon";
import api from "services/api";
import { addToast } from "app/components/Toast";

interface LinkRedirectModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  companyId: string;
  url: string;
  numberCollaborators: number;
  setLink: React.Dispatch<React.SetStateAction<string>>;
}

const LinkRedirectModal: React.FC<LinkRedirectModalProps> = ({
  open,
  setOpen,
  companyId,
  url,
  numberCollaborators,
  setLink,
}) => {
  const [loading, setLoading] = useState(false);
  const [linkValue, setLinkValue] = useState<string>(url);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => setOpen(false);

  const validateUrl = (url: string) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(url);
  };

  const updateLinkApi = async () => {
    const response = await api.put(`/companies/${companyId}`, {
      number_collaborators: numberCollaborators,
      redirects: [
        {
          type: "STEPONE",
          url: linkValue,
        },
      ],
    });
    return response.data;
  };

  const handleAction = () => {
    if (!validateUrl(linkValue)) {
      setError("Por favor, insira uma URL válida");
      return;
    }
    setError(null);
    setLoading(true);
    updateLinkApi()
      .then(() => {
        setLink(linkValue);
        addToast("Link atualizado com sucesso!", { appearance: "success" });
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        addToast("Erro ao atualizar o link", { appearance: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      icon={<CompaniesIcon />}
      open={open}
      onClose={handleClose}
      title="Integração StepOne"
    >
      {loading ? <LoadingComponent /> : null}
      <ModalContent>
        <p>Informe a URL da Step One</p>
        <InputBase
          type="url"
          value={linkValue}
          onChange={(e) => setLinkValue(e.target.value)}
          placeholder="https://url.com.br"
        />
        {error && <p style={{ color: "red", fontSize: "12px" }}>{error}</p>}
      </ModalContent>
      <ModalActions>
        <OutlinedButton size="small" onClick={handleClose}>
          Cancelar
        </OutlinedButton>
        <Button size="small" onClick={handleAction}>
          Atualizar
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default LinkRedirectModal;
