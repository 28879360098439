import React, { useEffect, useState } from "react";
import { WhiteContainer } from "components/WhiteContainer";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import BehavioralProfilesItem from "./BehavioralProfileItem";
import BehavioralProfileModal from "./BehavioralProfileModal";
import { OutlinedButton } from "components/Buttons/styles";
import { CompetenceInterface, CompetencesItemProps } from "interfaces/Job";

interface BehavioralProfilesProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  competencesList: CompetenceInterface[];
  allCompetencesList: CompetencesItemProps[];
  setCompetencesList: React.Dispatch<
    React.SetStateAction<CompetenceInterface[]>
  >;
}

const BehavioralProfiles: React.FC<BehavioralProfilesProps> = ({
  setModalOpen,
  allCompetencesList,
  competencesList,
  setCompetencesList,
}) => {
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const selectedCompetencesWithNames = competencesList.map(
    (selectedCompetence) => {
      const { competence_id, level, type } = selectedCompetence;
      const matchedCompetence = allCompetencesList.find(
        (competence) => competence.id === competence_id
      );

      return {
        competence_id,
        name: matchedCompetence ? matchedCompetence.name : "",
        level,
        type,
      };
    }
  );

  const competencesTypeBehavioral = selectedCompetencesWithNames.filter(
    (competence) => Number(competence.type) === 2
  );

  const competencesTypeTechnical = selectedCompetencesWithNames.filter(
    (competence) => Number(competence.type) === 1
  );

  const competencesTypeResult = selectedCompetencesWithNames.filter(
    (competence) => Number(competence.type) === 3
  );

  return (
    <>
      <WhiteContainer style={{ marginTop: "10px" }}>
        <GridContainer>
          <GridRow>
            <GridCol xs={12} md={12} lg={12}>
              <strong style={{ fontWeight: 600 }}>Perfil comportamental</strong>
              <p style={{ color: "rgba(135, 135, 135, 1)" }}>
                Selecione as competências técnicas, comportamentais e de
                resultado que são inerentes ao cargo.
              </p>
            </GridCol>
          </GridRow>
        </GridContainer>

        {competencesTypeBehavioral.length > 0 && (
          <GridContainer style={{ marginTop: "10px" }}>
            <GridRow>
              <GridCol xs={12} md={12} lg={12}>
                <strong style={{ fontWeight: 600 }}>
                  Competências comportamentais
                </strong>
              </GridCol>
            </GridRow>
            <GridRow>
              {competencesTypeBehavioral.map((comp) => (
                <GridCol xs={12} md={4} lg={4}>
                  <BehavioralProfilesItem
                    title={comp.name}
                    number={comp.level}
                    color="#D9FFFF"
                    setCompetencesList={setCompetencesList}
                    competenceId={comp.competence_id}
                  />
                </GridCol>
              ))}
            </GridRow>
          </GridContainer>
        )}

        {competencesTypeTechnical.length > 0 && (
          <GridContainer style={{ marginTop: "10px" }}>
            <GridRow>
              <GridCol xs={12} md={12} lg={12}>
                <strong style={{ fontWeight: 600 }}>
                  Competências Técnicas
                </strong>
              </GridCol>
            </GridRow>
            <GridRow>
              {competencesTypeTechnical.map((comp) => (
                <GridCol xs={12} md={4} lg={4}>
                  <BehavioralProfilesItem
                    title={comp.name}
                    number={comp.level}
                    color="#FFEBD9"
                    setCompetencesList={setCompetencesList}
                    competenceId={comp.competence_id}
                  />
                </GridCol>
              ))}
            </GridRow>
          </GridContainer>
        )}

        {competencesTypeResult.length > 0 && (
          <GridContainer style={{ marginTop: "10px" }}>
            <GridRow>
              <GridCol xs={12} md={12} lg={12}>
                <strong style={{ fontWeight: 600 }}>
                  Competências de Resultado
                </strong>
              </GridCol>
            </GridRow>
            <GridRow>
              {competencesTypeResult.map((comp) => (
                <GridCol xs={12} md={4} lg={4}>
                  <BehavioralProfilesItem
                    title={comp.name}
                    number={comp.level}
                    color="#D9FFDF"
                    setCompetencesList={setCompetencesList}
                    competenceId={comp.competence_id}
                  />
                </GridCol>
              ))}
            </GridRow>
          </GridContainer>
        )}

        <GridContainer style={{ marginTop: "10px" }}>
          <GridRow>
            <GridCol xs={12} sm={12} lg={12}>
              <OutlinedButton type="button" onClick={handleOpenModal}>
                Inserir competências
              </OutlinedButton>
            </GridCol>
          </GridRow>
        </GridContainer>
      </WhiteContainer>
    </>
  );
};

export default BehavioralProfiles;
